import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Api from "../../helpers/Api";
import EachPartAccordion from "./EachPartAccordion";
import { ThemeModeContext } from "../../context/ThemeModeContext";
import { lightTheme, darkTheme } from "../../components/SelectTheme";
const Custom = () => {
  const { DeviceID } = useParams();
  const { isDarkMode } = useContext(ThemeModeContext);
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceParts, setDeviceParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [colorDevice, setColorDevice] = useState({});
  const [colorDevices, setColorDevices] = useState([]);
  const [colorDevicesSelect, setColorDevicesSelect] = useState([]);
  const [mainThemes, setMainThemes] = useState([]);
  useEffect(() => {
    Api.CustomPage(DeviceID)
      .then((res) => {
        // console.log(res.data.device_parts_relations);
        setBrands(
          res.data.brands.map((brand) => ({
            value: brand.id,
            label: brand.brand_name,
          }))
        );
        setColorDevicesSelect(
          res.data.custom_color_devices.map((c_d) => ({
            value: c_d.id,
            label: c_d.cust_color_name,
          }))
        );
        setColorDevice(
          res.data.custom_color_devices[0]
            ? res.data.custom_color_devices[0]
            : {}
        );
        setColorDevices(res.data.custom_color_devices);
        setMainThemes(res.data.main_themes);
        setDeviceParts(res.data.device_parts_relations);
        setSelectedPart(res.data.device_parts_relations[0]?.id || null);
      })
      .catch((e) => {
        console.log(e);
      });
    // const applyMask = (part) => {
    //   const img = new Image();
    //   img.src = part.mask_img;
    //   img.onload = () => {
    //     const element = document.getElementById(`part_bg_${part.id}`);
    //     element.style.WebkitMaskImage = `url(${part.mask_img})`;
    //   };
    // };

    // deviceParts &&
    //   deviceParts.forEach((part) => {
    //     if (part.mask_img) {
    //       applyMask(part);
    //     }
    //   });
  }, [DeviceID]);

  // const mask =
  //   "https://techskin.bbwebs.in/masks/07-05-23back.png";
  // const mask =
  //   "https://tympanus.net/codrops-playground/assets/images/cssref/properties/mask-position/mask-image.png";
  // const mask =
  //   "https://s3-us-west-2.amazonaws.com/s.cdpn.io/234228/alpha-cat.png";
  // let mask = "../../assets/img/back-layer.png";
  // let mask = '../../assets/img/camera.png';
  const handleBrandChange = (selectedOption) => {
    if (selectedOption) {
      Api.getDevicesByBrand(selectedOption.value)
        .then((res) => {
          setDevices(
            res.data.devices.map((d) => ({
              value: d.id,
              label: d.model_no,
              is_custom: d.is_custom,
            }))
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // If no brand is selected, reset the devices list
      setDevices([]);
    }
  };
  const handleDeviceChange = (selectedOption) => {
    const newDeviceId = selectedOption.value;
    navigate(`/custom/${newDeviceId}`);
  };
  const handleColorChange = (selectedOption) => {
    const newColorDeviceId = selectedOption.value;
    const newColorDevice = colorDevices.find(
      (c_d) => c_d.id === newColorDeviceId
    );
    setColorDevice(newColorDevice);
  };
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4 col-6 mt-5">
          <div className="row mb-2">
            <div className="col-md-6 my-2">
              <Select
                options={brands}
                placeholder="Select Brand"
                onChange={handleBrandChange}
                styles={isDarkMode ? darkTheme : lightTheme}
              />
            </div>
            <div className="col-md-6 my-2">
              <Select
                options={devices}
                placeholder="Select Model"
                onChange={handleDeviceChange}
                styles={isDarkMode ? darkTheme : lightTheme}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <button className="btn back_btn" onClick={() => navigate(-1)}>
                <span
                  style={{
                    padding: "11px 13px ",
                    margin: "0px 10px 0px -20px",
                  }}
                >
                  <i className="fa fa-backward" aria-hidden="true"></i>
                </span>
                Back
              </button>
            </div>
            <div className="col-md-6 my-2">
              <Select
                options={colorDevicesSelect}
                placeholder="Select Colors"
                onChange={handleColorChange}
                styles={isDarkMode ? darkTheme : lightTheme}
              />
            </div>
          </div>
        </div>
        <div className="col-md-8 col-6 mt-5 pd-l-65 pd-r-90">
          <div className="row part_head mt-4">
            {deviceParts &&
              deviceParts.map((part) => (
                <div className="cus-col-md-3 text-center" key={part.id}>
                  <button
                    className={`btn part_btns ${
                      selectedPart === part.id ? "part_btn" : ""
                    }`}
                    onClick={() => setSelectedPart(part.id)}
                  >
                    {part.part_name}
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="product-single__photo-wrapper ">
            <div className="product-container product-single__media-group">
              <img
                id="product-mock-up"
                tabIndex={-1}
                data-sizes="auto"
                src={colorDevice?.cust_device_img}
                alt="{{ $device->model_no }}"
              />
              {/* {deviceParts &&
                deviceParts.map(
                  (part) =>
                    part.mask_img && (
                      <div
                        className='layover-img-Back'
                        style={{
                          // WebkitMaskImage: `url(${part.mask_img})`,
                          // WebkitMaskImage: `url(${mask})`,
                          WebkitMaskImage: `url('http://127.0.0.1:8000/masks/26-06-23IMG-03421.png')`,
                          crossOrigin: 'anonymous',
                        }}
                        key={part.id}
                        id={`part_bg_${part.id}`}
                      ></div>
                    )
                )} */}
              {deviceParts &&
                deviceParts.map(
                  (part) =>
                    part.mask_img && (
                      <>
                        {(() => {
                          const img = new Image();
                          img.src = part.mask_img;
                          img.onload = () => {
                            const maskImageURL = img.src; // Store the downloaded image URL
                            const element = document.getElementById(
                              `part_bg_${part.id}`
                            );
                            element.style.WebkitMaskImage = `url(${maskImageURL})`; // Use the downloaded image as WebkitMaskImage
                          };
                        })()}
                        <div 
                          className="layover-img-Back"
                          style={
                            {
                              // WebkitMaskImage: `url(${part.mask_img})`,
                              // WebkitMaskImage: `url(${mask})`,
                              // WebkitMaskImage: `url('http://127.0.0.1:8000/masks/26-06-23IMG-03421.png')`,
                              // crossOrigin: 'anonymous',
                            }
                          }
                          key={part.id}
                          id={`part_bg_${part.id}`}
                        ></div>
                      </>
                    )
                )}
            </div>
          </div>
        </div>
        <div className="col-md-8">
          {deviceParts &&
            deviceParts.map((part) =>
              selectedPart && selectedPart === part.id ? (
                <EachPartAccordion
                  key={part.id}
                  {...part}
                  mainThemes={mainThemes}
                />
              ) : null
            )}
        </div>
      </div>
    </div>
  );
};
export default Custom;
