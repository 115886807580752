import { useState } from "react";
import EachCustomSkin from "./EachCustomSkin";

const EachThemeBox = ({ subThemes, part_id }) => {
  const [currentSubThemeIndex, setCurrentSubThemeIndex] = useState(0);
  const [skins, setSkins] = useState(subThemes[currentSubThemeIndex]?.skins);
  const [currentSubTheme, setCurrentSubTheme] = useState(
    subThemes[currentSubThemeIndex]
  );
  const handlePrev = () => {
    if (currentSubThemeIndex > 0) {
      setCurrentSubThemeIndex(currentSubThemeIndex - 1);
      setCurrentSubTheme(subThemes[currentSubThemeIndex]);
      setSkins(subThemes[currentSubThemeIndex]?.skins);
    }
  };
  const handleNext = () => {
    if (currentSubThemeIndex < subThemes.length - 1) {
      setCurrentSubThemeIndex(currentSubThemeIndex + 1);
      setCurrentSubTheme(subThemes[currentSubThemeIndex]);
      setSkins(subThemes[currentSubThemeIndex]?.skins);
    }
  };
  return (
    <div className="scroll">
      <p className="text-success">{currentSubTheme?.sub_theme_name}</p>
      <div className="row">
        {skins &&
          skins.map((skin) => (
            <EachCustomSkin {...skin} key={skin.id} part_id={part_id} />
          ))}
      </div>
      {currentSubThemeIndex > 0 && <button onClick={handlePrev}>Prev</button>}
      {subThemes.length > 1 &&
        currentSubThemeIndex !== subThemes.length - 1 && (
          <button onClick={handleNext}>Next</button>
        )}
    </div>
  );
};
export default EachThemeBox;
