import React, { useState, useEffect, useContext } from 'react';
import ShareButton from '../components/ShareButton';
import {ThemeModeContext} from '../context/ThemeModeContext';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { toggleMode } = useContext(ThemeModeContext);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  /* Current year */
  const [currentYear, setCurrentYear] = useState('');
  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);
  const handleToggleMode = () => {
    toggleMode(); // Call the toggleMode function from the context
  };

  return (
    <footer className='footer mt-5'>
      <div className=' mt-8 pt-8 pb-4 d-none'>
        <div className='container mt-1 '>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='mb-6'>
                <h1 className='text-gray-100'> Tech Skins</h1>
              </div>

              <div className='mb-4'>
                <h6 className='mb-1 font-weight-bold text-gray-100'>
                  Contact info
                </h6>
                <address className='text-gray-100'>
                  Basthi, Hosur, TamilNadu-635109,India
                </address>
              </div>
              <div className='my-4 my-md-4'>
                <ShareButton />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Footer-bottom-widgets */}
      {/* Footer-copy-right */}
      <div className=' py-3'>
        <div className='container'>
          <div className='flex-center-between d-block d-md-flex'>
            <div className='mb-3 '>
              <ShareButton />
            </div>
            <div className='text-md-right'>
              <span className='d-inline-block  p-1'>
                ©{' '}
                <Link to='/' className='font-weight-bold text-gray-100'>
                  TechSkins{' '}
                </Link>
                {currentYear} - All rights Reserved
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* End Footer-copy-right */}
      <li className='list-inline-item dark-toggle'>
        <input
          type='checkbox'
          onClick={handleToggleMode}
          name='checkbox'
          className='switch'
        />
      </li>
      <button
        // style={{ display: isVisible ? "block" : "none" }}
        onClick={scrollToTop}
        id='scrollUp'
        className={`back-to-top d-flex align-items-center justify-content-center ${
          isVisible ? 'show' : ''
        }`}
      >
        <span className='d-none'>back to top</span>
      </button>
    </footer>
  );
};

export default Footer;
