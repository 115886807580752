import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
// import SearchBar from '../components/SearchBar';
// import MobileSearchBar from '../components/MobileSearchBar';
import HamburgerButton from "../components/HamburgerButton";
import { LoginAsideContext } from "../context/LoginAsideContext";
import AuthContext from "../context/AuthContext";
import Api from "../helpers/Api";
import EachSkinCartItem from "./EachSkinCartItem";
const Header = () => {
  const { isLoginAsideActive, setLoginAsideActive } =
    useContext(LoginAsideContext);
  const { user } = useContext(AuthContext);
  // const [registerBtnActive, setRegisterBtnActive] = useState(false);
  const [cartBtnActive, setCartBtnActive] = useState(false);
  const [skinsCartNotify, setSkinsCartNotify] = useState([]);
  const cartRef = useRef();
  useEffect(() => {
    const handleClickOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      //     console.log("cartBtnActive:", cartBtnActive);
      // console.log("cartRef.current:", cartRef.current);
      // console.log("Clicked target:", e.target);
      if (
        cartBtnActive &&
        cartRef.current &&
        !cartRef.current.contains(e.target)
      ) {
        // console.log("cartBtnActive:", cartBtnActive);
        // console.log("cartRef.current:", cartRef.current);
        // console.log("Clicked target:", e.target);
        setCartBtnActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    if (user.apiToken) {
      Api.CartHeader()
        .then((res) => {
          const { skins_in_cart } = res.data;
          setSkinsCartNotify(skins_in_cart);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [cartBtnActive]);
  // Update the state when the button is clicked
  const handleClick = (e) => {
    e.stopPropagation();
    setLoginAsideActive(!isLoginAsideActive);
  };
  return (
    <header id="header" className="u-header u-header-left-aligned-nav mb-3">
      <div className="u-header__section">
        <div className="bg-primary-down-lg">
          <div className="u-header-topbar py-2 d-none d-xl-block">
            <div className="container">
              <div className="d-flex align-items-center">
                <div className="topbar-left">
                  <Link
                    className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                    to="/"
                    aria-label="Tech Skin"
                  >
                    Logo
                  </Link>
                </div>
                <div className="topbar-right ml-auto mt-3 mb-3">
                  <ul className="list-inline mb-0 d-flex">
                    <div className="row">
                      {user.apiToken ? (
                        <>
                          <li
                            className = "col-md-6 pr-xl-0 px-2 px-sm-3 d-none d-xl-block  "
                            ref={cartRef}
                          >
                            <button
                              className={`btn  text-secondary drop_btn text-gray-90 position-relative d-flex  theme_flt_btn${
                                cartBtnActive ? "clicked" : ""
                              }`}
                              onClick={() => setCartBtnActive((prev) => !prev)}
                            >
                              <i className="font-size-22 icon-color ec ec-shopping-bag" />
                            </button>
                            {cartBtnActive && (
                              <div className="cart-dropdown d-block  dropdown-menu mt-3 border-left-0 border-right-0 border-bottom-0 left-auto right-0 ">
                                <h5 className=".border-bottom text-gray-5 py-2 pl-2">
                                  Products In Cart
                                </h5>
                                <ul className="list-unstyled px-3 pt-3">
                                  {skinsCartNotify.map((each) => (
                                    <EachSkinCartItem {...each} key={each.id}/>
                                  ))}
                                </ul>
                                <div className="flex-center-between px-4 pt-2">
                                  <Link
                                    to="/cart"
                                    className="btn theme_flt_btn mb-3 mb-md-0 font-weight-normal px-5 px-md-4 px-lg-5"
                                  >
                                    View cart
                                  </Link>
                                  <Link
                                    to="/checkout"
                                    className="btn theme_flt_btn ml-md-2 px-5 px-md-4 px-lg-5"
                                  >
                                    Checkout
                                  </Link>
                                </div>
                              </div>
                            )}
                          </li>
                          < li className = "col-md-6" >
                            <button className = {
                              ` btn text-secondary  text-gray-90 position-relative d-flex theme_flt_btn`
                            } style={{borderRadius:'2px',padding:'6px 10px'}}>
                              <i className="font-size-22 ec ec-user" />
                            </button>
                          </li>
                        </>
                      ) : (
                        <li className="col-md-6 list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border m-0">
                          {/* Account Sidebar Toggle Button */}

                          <button
                            onClick={handleClick}
                            className={`u-header-topbar__nav-link register_btn ${
                              isLoginAsideActive ? "active" : ""
                            }`}
                            id="login_btn"
                          >
                            <i className="ec ec-user mr-1" /> SIGN IN
                          </button>
                          {/* End Account Sidebar Toggle Button */}
                        </li>
                      )}

                      {/* <li className="col-md-6 list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                        <a
                          id="sidebarNavToggler"
                          role="button"
                          className={`u-header-topbar__nav-link register_btn ${
                            registerBtnActive ? "active" : ""
                          }`}
                          aria-controls="sidebarContent"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-unfold-event="click"
                          data-unfold-hide-on-scroll="false"
                          data-unfold-target="#sidebarContent"
                          data-unfold-type="css-animation"
                          data-unfold-animation-in="fadeInRight"
                          data-unfold-animation-out="fadeOutRight"
                          data-unfold-duration={500}
                          onClick={() => setRegisterBtnActive((prev) => !prev)}
                        >
                          <i className="ec ec-user mr-1" /> SIGN UP
                        </a>
                      </li> */}
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container my-0dot5 my-xl-0">
            <div className="row align-items-center py-3">
              <div className="col-auto">
                <nav className="navbar navbar-expand u-header__navbar py-0 justify-content-xl-between max-width-270 min-width-270">
                  <HamburgerButton
                    id="sidebarHeaderInvokerMenu"
                    className="navbar-toggler btn d-xl-none u-hamburger mr-3 mr-xl-0"
                    ariaControls="sidebarHeader"
                    dataUnfoldTarget="#sidebarHeader1"
                    icon={
                      <span
                        id="hamburgerTriggerMenu"
                        className="u-hamburger__box"
                      >
                        <span className="u-hamburger__inner" />
                      </span>
                    }
                  />
                </nav>

                <aside
                  id="sidebarHeader1"
                  className="u-sidebar u-sidebar--left"
                  aria-labelledby="sidebarHeaderInvoker"
                >
                  <div className="u-sidebar__scroller">
                    <div className="u-sidebar__container">
                      <div className="u-header-sidebar__footer-offset">
                        {/* Toggle Button */}
                        <div className="position-absolute top-0 right-0 z-index-2 pt-4 pr-4 ">
                          <HamburgerButton
                            id="q"
                            className="close ml-auto"
                            ariaControls="sidebarHeader"
                            dataUnfoldTarget="#sidebarHeader1"
                            icon={
                              <span aria-hidden="true">
                                <i className="ec ec-close-remove text-gray-90 font-size-20" />
                              </span>
                            }
                          />
                        </div>
                        {/* End Toggle Button */}
                        {/* Content */}
                        <div className="js-scrollbar u-sidebar__body">
                          <div
                            id="headerSidebarContent"
                            className="u-sidebar__content u-header-sidebar__content"
                          >
                            {/* Logo */}
                            <Link
                              className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center mb-3"
                              to="/"
                              aria-label="Tech Skin"
                            >
                              Logo
                            </Link>
                            {/* End Logo */}
                            {/* List */}
                            <ul
                              id="headerSidebarList"
                              className="u-header-collapse__nav"
                            >
                              <li>
                                <a
                                  className="u-header-collapse__nav-link font-weight-bold"
                                  href="#"
                                >
                                  New Arrivals
                                </a>
                              </li>

                              <li className="u-has-submenu u-header-collapse__submenu">
                                <a
                                  className="u-header-collapse__nav-link u-header-collapse__nav-pointer"
                                  data-target="#headerSidebarComputersCollapse"
                                  role="button"
                                  data-toggle="collapse"
                                  aria-expanded="false"
                                  aria-controls="headerSidebarComputersCollapse"
                                >
                                  Computers &amp; Accessories
                                </a>
                                <div
                                  id="headerSidebarComputersCollapse"
                                  className="collapse"
                                  data-parent="#headerSidebarContent"
                                >
                                  <ul className="u-header-collapse__nav-list">
                                    <li>
                                      <span className="u-header-sidebar__sub-menu-title">
                                        Computers &amp; Accessories
                                      </span>
                                    </li>
                                    <li>
                                      <a
                                        className="u-header-collapse__submenu-nav-link"
                                        href="#"
                                      >
                                        All Computers &amp; Accessories
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            {/* End List */}
                          </div>
                        </div>
                        {/* End Content */}
                      </div>
                      {/* Footer */}
                      <footer
                        id="SVGwaveWithDots"
                        className="svg-preloader u-header-sidebar__footer"
                      >
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item pr-3">
                            <a
                              className="u-header-sidebar__footer-link text-gray-90"
                              href="#"
                            >
                              Privacy
                            </a>
                          </li>
                          <li className="list-inline-item pr-3">
                            <a
                              className="u-header-sidebar__footer-link text-gray-90"
                              href="#"
                            >
                              Terms
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              className="u-header-sidebar__footer-link text-gray-90"
                              href="#"
                            >
                              <i className="fas fa-info-circle" />
                            </a>
                          </li>
                        </ul>
                        {/* SVG Background Shape */}
                        <div className="position-absolute right-0 bottom-0 left-0 z-index-n1">
                          <img
                            className="js-svg-injector"
                            src="../../assets/svg/components/wave-bottom-with-dots.svg"
                            alt=""
                            data-parent="#SVGwaveWithDots"
                          />
                        </div>
                        {/* End SVG Background Shape */}
                      </footer>
                      {/* End Footer */}
                    </div>
                  </div>
                </aside>
              </div>

              {/* <SearchBar /> */}

              <div className="col col-xl-auto text-right text-xl-left pl-0 pl-xl-3 position-static">
                <div className="d-inline-flex">
                  <ul className="d-flex list-unstyled mb-0 align-items-center">
                    {/* Search */}
                    <li className="col d-xl-none px-2 px-sm-3 position-static d-none">
                      <a
                        id="searchClassicInvoker"
                        className="font-size-22 text-gray-90 text-lh-1 btn-text-secondary"
                        role="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Search"
                        aria-controls="searchClassic"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-unfold-target="#searchClassic"
                        data-unfold-type="css-animation"
                        data-unfold-duration={300}
                        data-unfold-delay={300}
                        data-unfold-hide-on-scroll="true"
                        data-unfold-animation-in="slideInUp"
                        data-unfold-animation-out="fadeOut"
                      >
                        <span className="ec ec-search" />
                      </a>
                      {/* Input */}
                      {/* <MobileSearchBar /> */}
                      {/* End Input */}
                    </li>
                    {/* End Search */}
                    <li className="col d-none ">
                      <button className="btn_normal  theme_flt_btn ">
                        <i className="font-size-22 ec ec-favorites" />
                      </button>
                    </li>
                    <li className="col d-xl-none px-2 px-sm-3">
                      <a
                        href="../shop/my-account.html"
                        className="text-gray-90"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="My Account"
                      >
                        <i className="font-size-22 ec ec-user" />
                      </a>
                    </li>
                    <li className="col pr-xl-0 px-2 px-sm-3 d-xl-none">
                      <a
                        href="../shop/cart.html"
                        className="text-gray-90 position-relative d-flex "
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Cart"
                      >
                        <i className="font-size-22 ec ec-shopping-bag" />
                        <span className="bg-lg-down-black width-22 height-22 bg-primary position-absolute d-flex align-items-center justify-content-center rounded-circle left-12 top-8 font-weight-bold font-size-12">
                          2
                        </span>
                      </a>
                    </li>
                    {/* <li
                      className='col pr-xl-0 px-2 px-sm-3 d-none d-xl-block'
                      ref={cartRef}
                    >
                      <button
                        className={`btn  text-secondary drop_btn text-gray-90 position-relative d-flex  theme_flt_btn${
                          cartBtnActive ? 'clicked' : ''
                        }`}
                        onClick={() => setCartBtnActive((prev) => !prev)}
                      >
                        <i className='font-size-22 icon-color ec ec-shopping-bag' />
                      </button>
                      {cartBtnActive && (
                        <div className='cart-dropdown d-block  dropdown-menu  border-top border-top-primary mt-3 border-width-2 border-left-0 border-right-0 border-bottom-0 left-auto right-0 '>
                          <ul className='list-unstyled px-3 pt-3'>
                            <li className='border-bottom pb-3 mb-3'>
                              <div className>
                                <ul className='list-unstyled row mx-n2'>
                                  <li className='px-2 col-auto'>
                                    <img
                                      className='img-fluid'
                                      src='../../assets/img/75X75/img1.jpg'
                                      alt=''
                                    />
                                  </li>
                                  <li className='px-2 col'>
                                    <h5 className='text-blue font-size-14 font-weight-bold'>
                                      Ultra Wireless S50 Headphones S50 with
                                      Bluetooth
                                    </h5>
                                    <span className='font-size-14'>
                                      1 × $1,100.00
                                    </span>
                                  </li>
                                  <li className='px-2 col-auto'>
                                    <a href='#' className='text-gray-90'>
                                      <i className='ec ec-close-remove' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div className='flex-center-between px-4 pt-2'>
                            <Link
                              to='/cart'
                              className='btn btn-soft-secondary mb-3 mb-md-0 font-weight-normal px-5 px-md-4 px-lg-5'
                            >
                              View cart
                            </Link>
                            <a
                              href='../shop/checkout.html'
                              className='btn btn-primary-dark-w ml-md-2 px-5 px-md-4 px-lg-5'
                            >
                              Checkout
                            </a>
                          </div>
                        </div>
                      )}
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Logo-Search-header-icons */}
      </div>
    </header>
  );
};
export default Header;
