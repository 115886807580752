import React, { useState, useEffect, useMemo } from 'react';
import SliderSection from './SliderSection';
import EachCarouselSlide from './EachCarouselSlide';
import EachProductCard from './EachProductCard';
import Api from '../../helpers/Api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Parallax from '../../components/Parallax';

const Home = () => {
  
  const [categories, setCategories] = useState([]);
  const [features, setFeatures] = useState([]);
  const [deals, setDeals] = useState([]);
  const [dealActiveTab, setDealActiveTab] = useState(0);
  const [featureActiveTab, setFeatureActiveTab] = useState(0);
  const [banners, setBanners] = useState([]);
  const handleTabClick = (tab_num) => {
    setDealActiveTab(tab_num);
  };
  const handleFeatureTabClick = (tab_num) => {
    setFeatureActiveTab(tab_num);
  };
  useEffect(() => {
    Api.HomePage()
      .then((response) => {
        console.log(response.data)
        setCategories(response.data.categories);
        setFeatures(response.data.features);
        setDealActiveTab(response.data.categories[0]?.id);
        setFeatureActiveTab(response.data.categories[0]?.id);
        setDeals(response.data.hot_skins);
        setBanners(response.data.banners);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  // Memoized value for filtered features based on active tab
  const filteredFeatures = useMemo(() => {
    return features.filter((f) => f.category_id === featureActiveTab);
  }, [features, featureActiveTab]);
  // Memoized value for filtered deals based on active tab
  const filteredDeals = useMemo(() => {
    return deals.filter((deal) => deal.category_id === dealActiveTab);
  }, [deals, dealActiveTab]);
  return (
    <>
      <SliderSection />
      <div className='mb-5 container'>
        <Carousel responsive={responsive}>
          {categories.map((category) => {
            return <EachCarouselSlide key={category.id} {...category} />;
          })}
        </Carousel>
      </div>
      <div className='container'>
        {features.length > 0 && (
          <>
            <h3 className='font-color'>Featured Products</h3>
            <div className='card-box'>
              <div className='category_tabs '>
                {categories.map((category) => {
                  const categoryFeatures = features.filter(
                    (f) => f.category_id === category.id
                  );
                  if (categoryFeatures.length > 0) {
                    return (
                      <a
                        key={category.id}
                        className={`tab tab-h ${
                          featureActiveTab === category.id ? 'active' : ''
                        }`}
                        onClick={() => handleFeatureTabClick(category.id)}
                      >
                        {category.category_name}
                      </a>
                    );
                  }
                  return null; // Skip rendering the category tab if no items exist
                })}
              </div>
              <ul className='row list-unstyled products-group '>
                {filteredFeatures.map((feature) => (
                  <EachProductCard key={feature.id} {...feature} />
                ))}
              </ul>
            </div>
          </>
        )}
        <div className='container'>
          {banners.map((each_banner) => {
            const { id, page_id, section_id, banner_img, is_show } =
              each_banner;
            const shouldDisplayImage =
              page_id === '1' && section_id === '1' && is_show === '1';
            if (shouldDisplayImage) {
              return (
                <div className='col-md-12 banner_box_img' key={id}>
                  <img
                    src={`http://127.0.0.1:8000/${banner_img}`}
                    alt='banners'
                  />
                </div>
              );
            }

            return null; // Return null if the condition is not met
          })}
        </div>
        {deals.length > 0 && (
          <>
            <h3 className='font-color'>HOT DEALS</h3>
            <div className='card-box'>
              <div id='tabs' className='tabs-container'>
                <div className='category_tabs '>
                  {categories.map((category) => {
                    const categoryDeals = deals.filter(
                      (d) => d.category_id === category.id
                    );
                    if (categoryDeals.length > 0) {
                      return (
                        <a
                          key={category.id}
                          className={`tab tab-h ${
                            dealActiveTab === category.id ? 'active' : ''
                          }`}
                          onClick={() => handleTabClick(category.id)}
                        >
                          {category.category_name}
                        </a>
                      );
                    }
                    return null;
                  })}
                </div>

                <div className='content'>
                  <ul className='row list-unstyled products-group hot-deal'>
                    {filteredDeals // Filter deals based on category_id
                      .map((deal) => (
                        <EachProductCard key={deal.id} {...deal} />
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='container'>
          {banners.map((each_banner) => {
            const { id, page_id, section_id, banner_img, is_show } =
              each_banner;
            const shouldDisplayImage =
              page_id === '1' && section_id === '2' && is_show === '1';
            if (shouldDisplayImage) {
              return (
                <div className='banner_box_img' key={id}>
                  <img
                    src={`http://127.0.0.1:8000/${banner_img}`}
                    alt='banners'
                  />
                </div>
              );
            }

            return null; // Return null if the condition is not met
          })}
        </div>
      </div>
      {/* <Parallax /> */}
    </>
  );
};

export default Home;
