import React from "react";

const EachSlide = ({main_heading,sub_heading,image_src}) => {
  return (
    <div className="js-slide bg-img-hero-center">
      <div className="row height-410-xl py-7 py-md-0 mx-0">
        <div className="d-none d-wd-block offset-1" />
        <div className="col-xl col-6 col-md-6 mt-md-8">
          <h1
            className="font-size-64 text-lh-57 font-weight-light"
            data-scs-animation-in="fadeInUp"
          >
            THE NEW <span className="d-block font-size-55">{main_heading}</span>
          </h1>
          <h6
            className="font-size-15 font-weight-bold mb-3"
            data-scs-animation-in="fadeInUp"
            data-scs-animation-delay={200}
          >
           { sub_heading}
          </h6>
          <div
            className="mb-4"
            data-scs-animation-in="fadeInUp"
            data-scs-animation-delay={300}
          >
            <span className="font-size-13">FROM</span>
            <div className="font-size-50 font-weight-bold text-lh-45">
              <sup >$</sup>749<sup >99</sup>
            </div>
          </div>
          <a
            href="../shop/single-product-fullwidth.html"
            className="btn btn-primary transition-3d-hover rounded-lg font-weight-normal py-2 px-md-7 px-3 font-size-16"
            data-scs-animation-in="fadeInUp"
            data-scs-animation-delay={400}
          >
            Start Buying
          </a>
        </div>
        <div
          className="col-xl-7 col-6 d-flex align-items-center ml-auto ml-md-0"
          data-scs-animation-in="zoomIn"
          data-scs-animation-delay={500}
        >
          <img
            className="img-fluid"
            src={image_src}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default EachSlide;
