import React, { useState, useEffect } from "react";
import EachSlide from "./EachSlide";
import EachSlideCard from "./EachSlideCard";

const Slider = () => {
  const [slides, setSlides] = useState([]);
  let data = [
    {
      main_heading: "heading1",
      sub_heading: "UNDER FAVORABLE SMARTWATCHES1",
      image_src: "../../assets/img/500X380/img2.png",
    },
    {
      main_heading: "heading2",
      sub_heading: "UNDER FAVORABLE SMARTWATCHES2",
      image_src: "../../assets/img/500X380/img2.png",
    },
    {
      main_heading: "heading3",
      sub_heading: "UNDER FAVORABLE SMARTWATCHES3",
      image_src: "../../assets/img/500X380/img2.png",
    },
  ];
  useEffect(() => {
    setSlides(data);
  }, []);

  return (
    <>
      <div className='mb-4'>
        <div className='container overflow-hidden'>
          <div className='row '>
            <div className='col-xl pr-xl-2 mb-4 mb-xl-0'>
              <div
                className='bg-img-hero mr-xl-1 height-410-xl max-width-1060-wd max-width-830-xl overflow-hidden'
                style={{
                  backgroundImage: 'url(../../assets/img/1920X422/img1.jpg)',
                }}
              >
                <div
                  className='js-slick-carousel u-slick'
                  data-autoplay='true'
                  data-speed={7000}
                  data-pagi-classes='text-center  position-absolute right-0 bottom-0 left-0 u-slick__pagination u-slick__pagination--long justify-content-start ml-9 mb-3 mb-md-5'
                >
                  {slides.map((slide, index) => (
                    <EachSlide key={index} {...slide} />
                  ))}
                </div>
              </div>
            </div>

            <div className='col-xl-auto pl-xl-2 '>
              <div className='overflow-hidden'>
                <ul className='list-unstyled row flex-nowrap flex-xl-wrap overflow-auto overflow-lg-visble mx-n2 mx-xl-0 d-xl-block mb-0'>
                  <EachSlideCard />
                  <EachSlideCard />
                  <EachSlideCard />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-4'>
        <div
          className='bg-img-hero '
          style={{
            backgroundImage: 'url(../../assets/img/1920X422/img1.jpg)',
          }}
        >
          <div className='container overflow-hidden'>
            <div
              className='js-slick-carousel u-slick'
              data-pagi-classes='text-center position-absolute right-0 bottom-0 left-0 u-slick__pagination u-slick__pagination--long justify-content-center mb-3 mb-md-4'
            >
              <div className='js-slide'>
                <div className='row pt-7 py-md-0'>
                  <div className='d-none d-wd-block offset-1'></div>
                  <div className='col-xl col col-md-6 mt-md-8 mt-lg-10'>
                    <div className='ml-xl-4'>
                      <h6
                        className='font-size-15  font-weight-bold mb-2 text-cyan '
                        data-scs-animation-in='fadeInUp'
                      >
                        SHOP TO GET WHAT YOU LOVE
                      </h6>
                      <h1
                        className='font-size-46 text-lh-50 font-weight-light mb-8 '
                        data-scs-animation-in='fadeInUp'
                        data-scs-animation-delay='200'
                      >
                        TIMEPIECES THAT MAKE A STATEMENT UP TO{' '}
                        <strong className='font-weight-bold'>40% OFF</strong>
                      </h1>
                      <a
                        href='#'
                        className='btn btn-primary mb-5 mt-5 transition-3d-hover rounded-lg font-weight-normal py-2 px-md-7 px-3 font-size-16'
                        data-scs-animation-in='fadeInUp'
                        data-scs-animation-delay='300'
                      >
                        Start Buying
                      </a>
                    </div>
                  </div>
                  <div
                    className='col-xl-7 col-6 d-flex align-items-end ml-auto ml-md-0 slides'
                    data-scs-animation-in='fadeInRight'
                    data-scs-animation-delay='500'
                  >
                    <img
                      className='img-fluid ml-auto mr-10 mr-wd-auto'
                      src='../../assets/img/468X417/img1.png'
                      alt='Description'
                    />
                  </div>
                </div>
              </div>
              <div className='js-slide'>
                <div className='row pt-7 py-md-0'>
                  <div className='d-none d-wd-block offset-1'></div>
                  <div className='col-xl col col-md-6 mt-md-8 mt-lg-10'>
                    <div className='ml-xl-4'>
                      <h6
                        className='font-size-15 font-weight-bold mb-2  text-cyan'
                        data-scs-animation-in='fadeInUp'
                      >
                        SHOP TO GET WHAT YOU LOVE
                      </h6>
                      <h1
                        className='font-size-46 text-lh-50 font-weight-light mb-8 '
                        data-scs-animation-in='fadeInUp'
                        data-scs-animation-delay='200'
                      >
                        TIMEPIECES THAT MAKE A STATEMENT UP TO{' '}
                        <strong className='font-weight-bold'>40% OFF</strong>
                      </h1>
                      <a
                        href='#'
                        className='btn btn-primary mb-5 mt-5 transition-3d-hover rounded-lg font-weight-normal py-2 px-md-7 px-3 font-size-16'
                        data-scs-animation-in='fadeInUp'
                        data-scs-animation-delay='300'
                      >
                        Start Buying
                      </a>
                    </div>
                  </div>
                  <div
                    className='col-xl-7 col-6 flex-horizontal-center ml-auto ml-md-0 slides'
                    data-scs-animation-in='fadeInRight'
                    data-scs-animation-delay='500'
                  >
                    <img
                      className='img-fluid ml-auto mr-10 mr-wd-auto h-100'
                      src='../../assets/img/416X420/img1.png'
                      alt='Description'
                    />
                  </div>
                </div>
              </div>
              <div className='js-slide'>
                <div className='row pt-7 py-md-0'>
                  <div className='d-none d-wd-block offset-1'></div>
                  <div className='col-xl col col-md-6 mt-md-8 mt-lg-10'>
                    <div className='ml-xl-4'>
                      <h6
                        className='font-size-15 font-weight-bold mb-2 text-cyan '
                        data-scs-animation-in='fadeInUp'
                      >
                        SHOP TO GET WHAT YOU LOVE
                      </h6>
                      <h1
                        className='font-size-46 text-lh-50 font-weight-light mb-8 '
                        data-scs-animation-in='fadeInUp'
                        data-scs-animation-delay='200'
                      >
                        TIMEPIECES THAT MAKE A STATEMENT UP TO{' '}
                        <strong className='font-weight-bold'>40% OFF</strong>
                      </h1>
                      <a
                        href='#'
                        className='btn btn-primary mb-5 mt-5 transition-3d-hover rounded-lg font-weight-normal py-2 px-md-7 px-3 font-size-16'
                        data-scs-animation-in='fadeInUp'
                        data-scs-animation-delay='300'
                      >
                        Start Buying
                      </a>
                    </div>
                  </div>
                  <div
                    className='col-xl-7 col-6 flex-horizontal-center ml-auto ml-md-0 slides'
                    data-scs-animation-in='fadeInRight'
                    data-scs-animation-delay='500'
                  >
                      <img
                      className='img-fluid ml-auto mr-10 mr-wd-auto h-100'
                      src='../../assets/img/416X420/img1.png'
                      alt='Description'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
