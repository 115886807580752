const EachCustomSkin = ({ skin_name, thumbnail_img, id, part_id }) => {
  const handleApplySkin = () => {
    const element = document.getElementById(`part_bg_${part_id}`);
    if (element) {
      element.style.backgroundImage = `url(${thumbnail_img})`;
    }
  };
  return (
    <div className='col-md-2'>
      <div
        className='text-center img_ui'
        style={{ width: '50px; !important' }}
        onClick={handleApplySkin}
      >
        <img
          src={thumbnail_img}
          alt={skin_name}
          className='img-fluid thumbnail_img'
        />
        <p>{skin_name}</p>
        <h6>
          <strong>499</strong>
        </h6>
      </div>
    </div>
  );
};
export default EachCustomSkin;
