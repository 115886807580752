import React from "react";

const CouponAccordion = () => {
  return (
    <div id="shopCartAccordion1" className="accordion rounded mb-6">
      {/* Card */}
      <div className="card border-0">
        <div
          id="shopCartHeadingTwo"
          className="alert alert-primary mb-0"
          role="alert"
        >
          Have a coupon?{" "}
          <a
            href="#"
            className="alert-link"
            data-toggle="collapse"
            data-target="#shopCartTwo"
            aria-expanded="false"
            aria-controls="shopCartTwo"
          >
            Click here to enter your code
          </a>
        </div>
        <div
          id="shopCartTwo"
          className="collapse border border-top-0"
          aria-labelledby="shopCartHeadingTwo"
          data-parent="#shopCartAccordion1"
          style={{}}
        >
          <form className="js-validate p-5" noValidate="novalidate">
            <p className="w-100 text-gray-90">
              If you have a coupon code, please apply it below.
            </p>
            <div className="input-group input-group-pill max-width-660-xl">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Coupon code"
                aria-label="Promo code"
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-block btn-dark font-weight-normal btn-pill px-4"
                >
                  <i className="fas fa-tags d-md-none" />
                  <span className="d-none d-md-inline">Apply coupon</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
};

export default CouponAccordion;
