import Home from './pages/home/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import CategoryPage from './pages/filterpage/CategoryPage';
import Cart from './pages/cartpage/Cart';
import ProductDetail from './pages/detailpage/ProductDetail';
import Custom from './pages/custompage/Custom';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Checkout from './pages/checkoutpage/Checkout';

const App = () => {

  return (
    <BrowserRouter>
        <ToastContainer />
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/custom/:DeviceID" element={<Custom />}></Route>
          <Route path="/category/:categoryID" element={<CategoryPage />}></Route>
          <Route path='/cart' element={<Cart/>}></Route>
          <Route path='/checkout' element={<Checkout/>}></Route>
          <Route path='/:categoryName/:skinId' element={<ProductDetail/>}></Route>
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
};

export default App;
