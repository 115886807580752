import React, { useContext } from "react";
import Select, { components } from "react-select";
import { ThemeModeContext } from "../../context/ThemeModeContext";
import { lightTheme, darkTheme } from "../../components/SelectTheme";

const SelectWithCheckBox = ({
  themesOptions,
  setSelectedFilters,
  placeholder,
  objKey,
}) => {
  const { isDarkMode } = useContext(ThemeModeContext);
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            style={{  marginLeft: "15px" }}
          />
         
          <label>{props.label}</label>
          <img
            src={props.data.image} // Render the image for each option
            alt={props.label}
            style={{ width: "30px", height: "30px", marginLeft: "8px" }}
          />
        </components.Option>
      </div>
    );
  };
  return (
    <div>
      <Select
        options={themesOptions}
        placeholder={placeholder}
        isMulti
        components={{
          Option,
        }}
        allowSelectAll={true}
        onChange={(options) => {
          const selectedThemeOptions = options
            ? options.map((opt) => opt.value)
            : [];
          setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [objKey]: selectedThemeOptions,
          }));
        }}
        styles={isDarkMode ? darkTheme : lightTheme}
      />
    </div>
  );
};

export default SelectWithCheckBox;
