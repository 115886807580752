import Swal from "sweetalert2";

// https://sweetalert2.github.io/#examples
export const showConfirmAlert = (title, confirmText, denyText) => {
  return Swal.fire({
    title: title,
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: confirmText,
    denyButtonText: denyText,
  });
};

export const showErrorAlert = (title, text, footer) => {
  return Swal.fire({
    icon: "error",
    title: title,
    text: text,
    footer: footer,
  });
};
export const showSuccessAlert = (title) => {
  return Swal.fire({ icon: "success", title: title });
};
