import React from "react";
import { Link } from "react-router-dom";

const EachProductCard = ({ skin_name, skin_img, category_name, id ,reg_price,ofr_price}) => {
  return (
    <li className='col-md-2 col-lg-2  product-i'>
      <div className='product-item__outer h-100'>
        <div className='product-item__inner p-2'>
          <div className='product-item__body pb-xl-2'>
            <div className='mb-2'>
              <Link
                to={`/${category_name}/${id}`}
                className='font-size-12 text-gray-5'
              >
                {category_name}
              </Link>
            </div>
            <h5 className='mb-1 product-item__title'>
              <Link
                to={`/${category_name}/${id}`}
                className='text-blue font-weight-bold'
              >
                {skin_name}
              </Link>
            </h5>
            <div className='mb-2'>
              <Link
                to={`/${category_name}/${id}`}
                className='font-size-12 text-gray-5'
              >
                <img className='img-fluid pro_img' src={skin_img} alt='' />
              </Link>
            </div>
            <div className='mb-1 mt-4'>
              <div className='prodcut-price d-flex align-items-center  justify-content-between'>
                <div className='text-gray-100 reg_price text-decoration-line-through'>
                  ₹ {reg_price}
                </div>
                <div className='text-gray-100 ofr_price'>₹ {ofr_price}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default EachProductCard;
