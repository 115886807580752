import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBar from "./SideBar";
import { ThemeModeProvider } from "../context/ThemeModeContext";
import { LoginAsideProvider } from "../context/LoginAsideContext";
import { AuthProvider } from "../context/AuthContext";
const MainLayout = ({ children }) => {
  return (
    <div>
      <AuthProvider>
        <LoginAsideProvider>
          <ThemeModeProvider>
            <Header />
            {children}
            <SideBar />
            <Footer />
          </ThemeModeProvider>
        </LoginAsideProvider>
      </AuthProvider>
    </div>
  );
};
export default MainLayout;
