// ThemeContext.js
import React, { createContext, useEffect, useState } from 'react';

// Create the theme context
export const ThemeModeContext = createContext();

// Create a provider component for the theme context
export const ThemeModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
    } else {
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    }
  }, [isDarkMode]);
  return (
    <ThemeModeContext.Provider value={{ isDarkMode, toggleMode }}>
      {children}
    </ThemeModeContext.Provider>
  );
};
