const lightTheme = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#f3f4f7' : '#f3f4f7',
    borderRadius: '17px',
    boxShadow: state.isFocused
      ? `-1.5px -1.5px 4px rgba(255, 255, 255, 0.96),
          1px 1px 2px rgba(0, 0, 0, 0.28)`
      : `-1.5px -1.5px 4px rgba(255, 255, 255, 0.96),
          1px 1px 2px rgba(0, 0, 0, 0.28)`,
    border: state.isFocused ? 'none' : 'none',
    '&:hover': {
      // Hover styles
      boxShadow: `inset 3px 3px 5px #cbcbcb, inset -3px -3px 5px #ffffff`,
      cursor: 'pointer',
    },
    '&:active': {
      // Active styles
      color: '#878787',
      boxShadow: `inset 3px 3px 5px #cbcbcb, inset -3px -3px 5px #ffffff`,
      cursor: 'pointer',
      borderRadius: '20px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'transparent' : 'transparent',
    color: state.isSelected ? '#40e0d0' : '#878787',
    '&:hover': {
      // Hover styles
      boxShadow: `inset 3px 3px 5px #cbcbcb, inset -3px -3px 5px #ffffff`,
      cursor: 'pointer',
      borderRadius: '20px',
    },
    '&:active': {
      // Active styles
      boxShadow: `inset 3px 3px 5px #cbcbcb, inset -3px -3px 5px #ffffff`,
      cursor: 'pointer',
      borderRadius: '20px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#F6F6F6',
    boxShadow: `-1.5px -1.5px 4px rgba(255, 255, 255, 0.96),
    1px 1px 2px rgba(0, 0, 0, 0.28)`,
    borderRadius: '8px',
  }),
};
const darkTheme = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#292d32' : '#292d32',
    borderRadius: '20px',
    boxShadow: state.isFocused
      ? `-1.5px -1.5px 4px rgba(100, 96, 96, 0.56),
      2px 2px 3px rgba(0, 0, 0, 0.41)`
      : `-1.5px -1.5px 4px rgba(100, 96, 96, 0.56),
      2px 2px 3px rgba(0, 0, 0, 0.41)`,
    border: state.isFocused ? 'none' : 'none',
    '&:hover': {
      // Hover styles

      boxShadow: `inset -1.5px -1.5px 4px rgba(100, 96, 96, 0.56),
      inset 2px 2px 3px rgba(0, 0, 0, 0.41)`,
      cursor: 'pointer',
    },
    '&:active': {
      // Active styles
      color: '#878787',
      boxShadow: `inset -1.5px -1.5px 4px rgba(100, 96, 96, 0.56),
      inset 2px 2px 3px rgba(0, 0, 0, 0.41)`,
      cursor: 'pointer',
      borderRadius: '20px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#292d32' : '#292d32',
    color: state.isSelected ? '#40e0d0' : '#878787',
    borderRadius: '20px',

    '&:hover': {
      // Hover styles
      boxShadow: `inset -1.5px -1.5px 4px rgba(100, 96, 96, 0.56),
      inset 2px 2px 3px rgba(0, 0, 0, 0.41)`,
      cursor: 'pointer',
      borderRadius: '20px',
    },
    '&:active': {
      // Active styles
      boxShadow: `inset -1.5px -1.5px 4px rgba(100, 96, 96, 0.56),
      inset 2px 2px 3px rgba(0, 0, 0, 0.41)`,
      cursor: 'pointer',
      borderRadius: '20px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#292d32',
    boxShadow: `-1.5px -1.5px 4px rgba(100, 96, 96, 0.56),
    2px 2px 3px rgba(0, 0, 0, 0.41)`,
    borderRadius: '20px',
  }),
};

export { lightTheme, darkTheme };
