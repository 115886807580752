import axios from "axios";

// const baseURL = "http://127.0.0.1:8000/api";
// const baseURL = "https://demo-backend.techskins.in/api";

let baseURL = "";

// Check if the code is running in a local environment
if (process.env.NODE_ENV === "development") {
  baseURL = "http://127.0.0.1:8000/api";
} else {
  baseURL = "https://demo-backend.techskins.in/api";
}
const CustomAxios = (contentType) => {
  const apiToken = localStorage.getItem("apiToken");
  // const csrfToken = localStorage.getItem("csrfToken"); // Fetch the CSRF token from local storage
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      "Content-Type": contentType,
      // "X-CSRF-TOKEN": csrfToken, // Include the CSRF token in the request headers
      // Add the authentication token to the request headers
      Authorization: apiToken ? `Bearer ${apiToken}` : null,
    },
  });
  return axiosInstance;
};

export default CustomAxios;
