import React from "react";

const RelatedProducts = ({ deviceRelatedKit, onCheckboxChange,addToCartData  }) => {
  return (
    <>
      {deviceRelatedKit.map((each_kit_item, k) => (
        <div className="row col-md-12 mt-5 mb-2" key={each_kit_item.id}>
          <div className="col-md-4">
            <div className="skin_box_img">
              <img
                src={each_kit_item.img_path}
                alt={each_kit_item.heading}
                className="img-fluid"
                style={{ width: "100px" }}
              />
            </div>
          </div>
          <div className="col-md-7">
            <h4 className="font-color">{each_kit_item.heading}</h4>
            <p className="font-17">{each_kit_item.desc}</p>
            <p className="font-17">₹ {each_kit_item.price}</p>
          </div>
          <div className="col-md-1">
            <input
              type="checkbox"
              name="device_extra_items[]"
              className="check-box"
              value={each_kit_item.id}
              checked={addToCartData.device_extra_items.includes(each_kit_item.id)}
              onChange={(e) =>
                onCheckboxChange(e.target.checked, each_kit_item.id)
              }
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default RelatedProducts;
