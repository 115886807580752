import React from "react";

const HamburgerButton = ({ id, className, icon,ariaControls,dataUnfoldTarget }) => {
  return (
    <button
      id={id}
      type="button"
      className={ className}
      aria-controls={ariaControls}
      aria-haspopup="true"
      aria-expanded="false"
      data-unfold-event="click"
      data-unfold-hide-on-scroll="false"
      data-unfold-target={dataUnfoldTarget}
      data-unfold-type="css-animation"
      data-unfold-animation-in="fadeInLeft"
      data-unfold-animation-out="fadeOutLeft"
      data-unfold-duration={500}
    >
      {icon}
    </button>
  );
};

export default HamburgerButton;
