import React, { useContext, useEffect, useRef, useState } from "react";
import { LoginAsideContext } from "../context/LoginAsideContext";
import Api from "../helpers/Api";
import AuthContext from "../context/AuthContext";
const SideBar = () => {
  const loginInitial = {
    email: "",
    password: "",
  };
  // Add this line to access the login function from AuthContext
  const { login } = useContext(AuthContext);
  const [loginData, setLoginData] = useState(loginInitial);
  const { isLoginAsideActive, setLoginAsideActive } =
    useContext(LoginAsideContext);
  const asideRef = useRef(null);
  const handleClickOutside = (event) => {
    if (asideRef.current && !asideRef.current.contains(event.target)) {
      setLoginAsideActive(false);
    }
  };
  useEffect(() => {
    if (isLoginAsideActive) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isLoginAsideActive]);
  const handleAsideClose = (e) => {
    e.stopPropagation();
    setLoginAsideActive(!isLoginAsideActive);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    const requestBody = JSON.stringify(loginData);
    Api.Login(requestBody)
      .then((res) => {
        const responseData = res.data;
        // Store the API token, user name, and user ID in the local storage
        localStorage.setItem("apiToken", responseData.token);
        localStorage.setItem("userName", responseData.name);
        localStorage.setItem("userId", responseData.user_id);
        setLoginAsideActive(false);
        login({
          apiToken: responseData.token,
          userName: responseData.name,
          userId: responseData.user_id,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <aside
      id="sidebarbox"
      ref={asideRef}
      className={`u-sidebar u-sidebar__lg  tester ${
        isLoginAsideActive ? "is-active" : ""
      }`}
      // aria-labelledby="sidebarNavToggler"
    >
      <div className="u-sidebar__scroller">
        <div className="u-sidebar__container">
          <div className="js-scrollbar u-header-sidebar__footer-offset pb-3">
            {/* Toggle Button */}
            <div className="d-flex align-items-center pt-4 px-7">
              <button
                type="button"
                className="close ml-auto"
                onClick={handleAsideClose}
              >
                <i className="ec ec-close-remove" />
              </button>
            </div>
            {/* End Toggle Button */}
            {/* Content */}
            <div className="js-scrollbar u-sidebar__body">
              <div className="u-sidebar__content u-header-sidebar__content">
                <form className="js-validate">
                  {/* Login */}
                  <div id="login" data-target-group="idForm">
                    {/* Title */}
                    <header className="text-center mb-7">
                      <h2 className="h4 mb-0">Welcome Back!</h2>
                      <p>Login to manage your account.</p>
                    </header>
                    {/* End Title */}
                    {/* Form Group */}
                    <div className="form-group">
                      <div className="slide_field">
                        <span className="fas fa-user" />
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="signinEmail"
                          value={loginData.email} // Use loginData.password as the value for the input field
                          onChange={handleInputChange} // Use handleInputChange to update loginData
                          placeholder="Email"
                          aria-label="Email"
                          aria-describedby="signinEmailLabel"
                          required
                          data-msg="Please enter a valid email address."
                          data-error-class="u-has-error"
                          data-success-class="u-has-success"
                        />
                        {/* <label>Email Id</label> */}
                      </div>
                      <div className="slide_field">
                        <span className="fas fa-lock" />
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          id="signinPassword"
                          placeholder="Password"
                          aria-label="Password"
                          value={loginData.password} // Use loginData.password as the value for the input field
                          onChange={handleInputChange} // Use handleInputChange to update loginData
                          aria-describedby="signinPasswordLabel"
                          required
                          data-msg="Your password is invalid. Please try again."
                          data-error-class="u-has-error"
                          data-success-class="u-has-success"
                        />
                        <label className="sr-only" htmlFor="signinPassword">
                          Password
                        </label>
                      </div>
                    </div>
                    {/* End Form Group */}

                    <div className="d-flex justify-content-end mb-4">
                      <a
                        className="js-animation-link small link-muted"
                        data-target="#forgotPassword"
                        data-link-group="idForm"
                        data-animation-in="slideInUp"
                      >
                        Forgot Password?
                      </a>
                    </div>
                    <div className="mb-2">
                      <button
                        type="button"
                        className="btn btn-block btn-sm  transition-3d-hover lgn_btn"
                        onClick={handleLogin}
                      >
                        Login
                      </button>
                    </div>
                    <div className="text-center mb-4">
                      <span className="small text-muted">
                        Do not have an account?
                      </span>
                      <a
                        className="js-animation-link small text-dark"
                        data-target="#signup"
                        data-link-group="idForm"
                        data-animation-in="slideInUp"
                      >
                        Signup
                      </a>
                    </div>
                    <div className="text-center">
                      <span className="u-divider u-divider--xs u-divider--text mb-4">
                        OR
                      </span>
                    </div>
                    {/* Login Buttons */}
                    <div className="d-flex">
                      <a
                        className="btn btn-block btn-sm btn-facebook-cus lgn_btn transition-3d-hover mr-1"
                        href="#"
                      >
                        <span className="fab fa-facebook-square mr-1" />
                        Facebook
                      </a>
                      <a
                        className="btn btn-block btn-sm btn-google-cus lgn_btn transition-3d-hover ml-1 mt-0"
                        href="#"
                      >
                        <span className="fab fa-google mr-1" />
                        Google
                      </a>
                    </div>
                    {/* End Login Buttons */}
                  </div>
                  {/* Signup */}
                  <div
                    id="signup"
                    style={{ display: "none", opacity: 0 }}
                    data-target-group="idForm"
                  >
                    {/* Title */}
                    <header className="text-center mb-7">
                      <h2 className="h4 mb-0">Welcome to Electro.</h2>
                      <p>Fill out the form to get started.</p>
                    </header>
                    {/* End Title */}
                    {/* Form Group */}
                    <div className="form-group">
                      <div className="js-form-message js-focus-state">
                        <div className="slide_field">
                          <span className="fas fa-user" />
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="signupEmail"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="signupEmailLabel"
                            required
                            data-msg="Please enter a valid email address."
                            data-error-class="u-has-error"
                            data-success-class="u-has-success"
                          />
                          {/* <label>Email Id</label> */}
                        </div>
                      </div>
                    </div>
                    {/* End Input */}
                    {/* Form Group */}
                    <div className="form-group">
                      <div className="js-form-message js-focus-state">
                        <div className="slide_field">
                          <span className="fas fa-lock" />
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="signupPassword"
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="signupPasswordLabel"
                            required
                            data-msg="Your password is invalid. Please try again."
                            data-error-class="u-has-error"
                            data-success-class="u-has-success"
                          />
                          <label className="sr-only" htmlFor="signupPassword">
                            Password
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* End Input */}
                    {/* Form Group */}
                    <div className="form-group">
                      <div className="js-form-message js-focus-state">
                        <div className="slide_field">
                          <span className="fas fa-key" />
                          <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            id="signupConfirmPassword"
                            placeholder="Confirm Password"
                            aria-label="Confirm Password"
                            aria-describedby="signupConfirmPasswordLabel"
                            required
                            data-msg="Password does not match the confirm password."
                            data-error-class="u-has-error"
                            data-success-class="u-has-success"
                          />
                          <label
                            className="sr-only"
                            htmlFor="signupConfirmPassword"
                          >
                            Confirm Password
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* End Input */}
                    <div className="mb-2">
                      <button
                        type="submit"
                        className="btn btn-block btn-sm lgn_btn transition-3d-hover"
                      >
                        Get Started
                      </button>
                    </div>
                    <div className="text-center mb-4">
                      <span className="small text-muted">
                        Already have an account?
                      </span>
                      <a
                        className="js-animation-link small text-dark"
                        data-target="#login"
                        data-link-group="idForm"
                        data-animation-in="slideInUp"
                      >
                        Login
                      </a>
                    </div>
                    <div className="text-center">
                      <span className="u-divider u-divider--xs u-divider--text mb-4">
                        OR
                      </span>
                    </div>
                    {/* Login Buttons */}
                    <div className="d-flex">
                      <a
                        className="btn btn-block btn-sm lgn_btn btn-facebook-cus transition-3d-hover mr-1"
                        href="#"
                      >
                        <span className="fab fa-facebook-square mr-1" />
                        Facebook
                      </a>
                      <a
                        className="btn btn-block btn-sm lgn_btn btn-google-cus transition-3d-hover ml-1 mt-0"
                        href="#"
                      >
                        <span className="fab fa-google mr-1" />
                        Google
                      </a>
                    </div>
                    {/* End Login Buttons */}
                  </div>
                  {/* End Signup */}
                  {/* Forgot Password */}
                  <div
                    id="forgotPassword"
                    style={{ display: "none", opacity: 0 }}
                    data-target-group="idForm"
                  >
                    {/* Title */}
                    <header className="text-center mb-7">
                      <h2 className="h4 mb-0">Recover Password.</h2>
                      <p>
                        Enter your email address and an email with instructions
                        will be sent to you.
                      </p>
                    </header>
                    {/* End Title */}
                    {/* Form Group */}
                    <div className="form-group">
                      <div className="js-form-message js-focus-state">
                        <label className="sr-only" htmlFor="recoverEmail">
                          Your email
                        </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="recoverEmailLabel"
                            >
                              <span className="fas fa-user" />
                            </span>
                          </div>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="recoverEmail"
                            placeholder="Your email"
                            aria-label="Your email"
                            aria-describedby="recoverEmailLabel"
                            required
                            data-msg="Please enter a valid email address."
                            data-error-class="u-has-error"
                            data-success-class="u-has-success"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Form Group */}
                    <div className="mb-2">
                      <button
                        type="submit"
                        className="btn btn-block btn-sm btn-primary transition-3d-hover"
                      >
                        Recover Password
                      </button>
                    </div>
                    <div className="text-center mb-4">
                      <span className="small text-muted">
                        Remember your password?
                      </span>
                      <a
                        className="js-animation-link small"
                        data-target="#login"
                        data-link-group="idForm"
                        data-animation-in="slideInUp"
                      >
                        Login
                      </a>
                    </div>
                  </div>
                  {/* End Forgot Password */}
                </form>
              </div>
            </div>
            {/* End Content */}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
