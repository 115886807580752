import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Api from "../../helpers/Api";
import ThemeBtn from "./ThemeBtn";
import ThemeBtnWithSub from "./ThemeBtnWithSub";
import EachProductCard from "./EachProductCard";
import { Link } from "react-router-dom";
import { lightTheme, darkTheme } from "../../components/SelectTheme";
import { ThemeModeContext } from "../../context/ThemeModeContext";
import SelectWithCheckBox from "./SelectWithCheckBox";

const CategoryPage = () => {
  const { categoryID } = useParams();
  const [category, setCategory] = useState();
  const [themesOptions, setThemesOptions] = useState([]);
  const [subThemesOptions, setSubThemesOptions] = useState([]);
  const { isDarkMode } = useContext(ThemeModeContext);
  const [brands, setBrands] = useState([]);
  const filterInitial = {
    brand_id: null,
    device_id: null,
    is_custom: null,
    skin_themes_id: [],
    sub_themes_id: [],
    category_id: categoryID,
  };
  const [selectedFilters, setSelectedFilters] = useState(filterInitial);
  const [devices, setDevices] = useState([]);
  const [products, setProducts] = useState([]);
  const [shouldShowCustomizeButton, setShouldShowCustomizeButton] =
    useState(false);
  useEffect(() => {
    Api.FilterPage(categoryID)
      .then((res) => {
        setCategory(res.data.category);
        setThemesOptions(
          res.data.themes.map((theme) => ({
            value: theme.id,
            label: theme.theme_name,
            image: theme.theme_logo,
          }))
        );
        setBrands(
          res.data.brands.map((brand) => ({
            value: brand.id,
            label: brand.brand_name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [categoryID]);
  useEffect(() => {
    // Convert to JSON string
    const requestBody = JSON.stringify(selectedFilters);
    Api.makeFilterPost(requestBody)
      .then((res) => {
        setProducts(res.data.skins);
        // Check if brand_id and device_id are not null
        const isButtonVisible =
          selectedFilters.device_id !== null && selectedFilters.is_custom;
        setShouldShowCustomizeButton(isButtonVisible);
        setSubThemesOptions(
          res.data.sub_themes.map((sub_theme) => ({
            value: sub_theme.sub_theme_id,
            label: sub_theme.sub_theme_name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selectedFilters]);
  const handleBrandChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      device_id: null,
      brand_id: selectedOption?.value,
    }));
    if (selectedOption) {
      Api.getDevicesByBrand(selectedOption.value)
        .then((res) => {
          setDevices(
            res.data.devices.map((d) => ({
              value: d.id,
              label: d.model_no,
              is_custom: d.is_custom,
            }))
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // If no brand is selected, reset the devices list
      setDevices([]);
    }
  };
  const handleDeviceChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      device_id: selectedOption?.value,
      is_custom: selectedOption?.is_custom === 1 ? true : false,
    }));
  };
  const handleReset = () => {
    setSelectedFilters(filterInitial);
  };
  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col-md-3" style={{ marginTop: "34px" }}>
            <div className="row skin_box">
              <div className="col-md-5">
                <div className="skin_box_img">
                  <img
                    className="img-fluid  p-1 "
                    src={category?.category_logo}
                    alt={category?.category_name}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h5>{category?.category_name} Skins</h5>
              </div>
            </div>
          </div>
          <div className="col-md-5 p-2">
            <h5 className="text-center font-color">Themes</h5>
            <div className="row mt-3">
              <div className="col-md-6">
                <SelectWithCheckBox
                  themesOptions={themesOptions}
                  setSelectedFilters={setSelectedFilters}
                  placeholder="Select Themes"
                  objKey="skin_themes_id"
                />
              </div>
              <div className="col-md-6">
                <SelectWithCheckBox
                  themesOptions={subThemesOptions}
                  setSelectedFilters={setSelectedFilters}
                  placeholder="Select Sub Themes"
                  objKey="sub_themes_id"
                />
              </div>

              {/* {themes.map((theme) => (
                  <div className="" key={theme.id}>
                    {theme.sub_themes.length > 0 ? (
                      <ThemeBtnWithSub
                        {...theme}
                        setSelectedFilters={setSelectedFilters}
                      />
                    ) : (
                      <ThemeBtn {...theme} />
                    )}
                  </div>
                ))} */}

              {/* <div className="col-md-6">
                {themes.map((theme) => (
                  <div className="" key={theme.id}>
                    {theme.sub_themes.length > 0 ? (
                      <ThemeBtnWithSub
                        {...theme}
                        setSelectedFilters={setSelectedFilters}
                      />
                    ) : (
                      <ThemeBtn {...theme} />
                    )}
                  </div>
                ))}
              </div> */}
              <div className="col-md-4"></div>
              <div className="col-md-4 py-4">
                <button
                  type="button"
                  className="btn  theme_flt_btn"
                  onClick={handleReset}
                >
                  Reset <i className="fa fa-rotate" aria-hidden="true"></i>
                </button>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
          <div className="col-md-4 p-2">
            <h5 className="text-center font-color">Choose Your Device</h5>
            <div className="row">
              <div className="col-md-6 my-2">
                <Select
                  options={brands}
                  placeholder="Select Brand"
                  onChange={handleBrandChange}
                  styles={isDarkMode ? darkTheme : lightTheme}
                />
              </div>
              <div className="col-md-6 my-2">
                <Select
                  options={devices}
                  placeholder="Select Model"
                  onChange={handleDeviceChange}
                  styles={isDarkMode ? darkTheme : lightTheme}
                />
              </div>
            </div>
            {shouldShowCustomizeButton && (
              <div className="row">
                <div className="col-md-12 p-2">
                  <Link to={`/custom/${selectedFilters.device_id}`}>
                    <button type="button" className="cust_skin_btn btn ">
                      Customize Your Skin
                      <span>
                        <img
                          className="img-icon  p-1 "
                          src="../../assets/img/customize-3.png"
                          alt=" Description"
                        />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          {products.map((product) => (
            <EachProductCard
              {...product}
              key={product.id}
              category_name={category?.category_name}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CategoryPage;
