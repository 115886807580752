import React from 'react'

const CommonKitProduct = ({heading,price,quantity}) => {
  return (
    <tr className="cart_item">
    <td>
      {heading}
      <strong className="product-quantity">× {quantity}</strong>
    </td>
    <td>${price * quantity}</td>
  </tr>
  )
}

export default CommonKitProduct