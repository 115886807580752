import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Api from "../../helpers/Api";
import EachThumbnail from "./EachThumbnail";
import Customize from "./Customize";
import RelatedProducts from "./RelatedProducts";
import AddToCart from "./AddToCart";
import CategoryRelatedProducts from "./CategoryRelatedProducts";
import AuthContext from "../../context/AuthContext";
import { LoginAsideContext } from "../../context/LoginAsideContext";
import { showSuccessAlert } from "../../helpers/SweetAlert";

const ProductDetail = () => {
  const { user } = useContext(AuthContext);
  const { setLoginAsideActive } =
    useContext(LoginAsideContext);
  const { skinId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [activeSubTheme, setActiveSubTheme] = useState(0);
  const [activeSkin, setActiveSkin] = useState(null);
  const [deviceRelatedSkins, setDeviceRelatedSkins] = useState([]);
  const [subThemeWithSkins, setSubThemeWithSkins] = useState([]);
  const [deviceRelatedThemes, setDeviceRelatedThemes] = useState([]);
  const [skinRelatedKit, setSkinRelatedKit] = useState([]);
  const [deviceRelatedKit, setDeviceRelatedKit] = useState([]);
  const [categoryRelatedKit, setCategoryRelatedKit] = useState([]);
  const [colorRelatedSkins, setColorRelatedSkins] = useState([]);
  const [finishes, setFinishes] = useState([]);
  const filterInitial = {
    skin_id: skinId,
    skin_theme_id:
      deviceRelatedThemes.length > 0 ? deviceRelatedThemes[0].id : null,
  };
  const addToCartDataInit = {
    skin_id: activeSkin?.id,
    skin_related_kit: null,
    with_logo: 0,
    device_extra_items: [],
    category_extra_items: [],
    finish_id: null,
  };
  const [selectedFilters, setSelectedFilters] = useState(filterInitial);
  const [addToCartData, setAddToCartData] = useState(addToCartDataInit);

  useEffect(() => {
    Api.DetailPage(skinId)
      .then((res) => {
        const {
          skin,
          device_related_skins,
          colorRelatedSkins,
          device_related_themes,
          category_related_kit_parts,
        } = res.data;
        setActiveSkin(skin);
        setAddToCartData((prev) => ({ ...prev, skin_id: skin.id }));
        setDeviceRelatedSkins(device_related_skins);
        setDeviceRelatedThemes(device_related_themes);
        setCategoryRelatedKit(category_related_kit_parts);
        setSelectedFilters((prev) => ({
          ...prev,
          skin_theme_id: device_related_themes[0]?.id,
        }));
        setColorRelatedSkins(colorRelatedSkins);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [skinId]);
  useEffect(() => {
    if (activeSkin) {
      Api.getRelatedKitBySkin(activeSkin.id)
        .then((res) => {
          const { related_kit_parts, device_related_kit_parts, finishes } =
            res.data;
          setSkinRelatedKit(related_kit_parts);
          setDeviceRelatedKit(device_related_kit_parts);
          setFinishes(finishes);
          setAddToCartData((prev) => ({ ...prev, skin_id: activeSkin.id }));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [activeSkin]);
  useEffect(() => {
    Api.ProdDetailFilter(selectedFilters.skin_id, selectedFilters.skin_theme_id)
      .then((res) => {
        // console.log(res.data);
        // Create a new object in sub_with_skins
        const newObjectForMainThemeBasedSkins = {
          sub_theme_name: "",
          sub_theme_id: 0,
          skins_arr: res.data.main_theme_Based_skins,
        };
        // Add the new object at the beginning of sub_with_skins
        res.data.sub_with_skins.unshift(newObjectForMainThemeBasedSkins);
        setSubThemeWithSkins(res.data.sub_with_skins);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selectedFilters]);
  const handlePrevClick = useCallback(
    (id) => {
      const filteredSkin = deviceRelatedSkins.find((skin) => skin.id === id);
      setActiveSkin(filteredSkin);
    },
    [deviceRelatedSkins]
  );
  // Function to show the previous set of sub-themes
  const handlePrevSubTheme = () => {
    setActiveSubTheme((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Function to show the next set of sub-themes
  const handleNextSubTheme = () => {
    setActiveSubTheme((prevIndex) =>
      Math.min(prevIndex + 1, subThemeWithSkins.length - 1)
    );
  };
  const tabRefs = useRef([]);

  const handleTabClick = (index) => {
    setActiveTab(index);

    // Scroll to the clicked tab's position
    if (tabRefs.current && tabRefs.current[index]) {
      tabRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      skin_theme_id: deviceRelatedThemes[index].id,
    }));
  };

  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const handleSkinKit = (itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      skin_related_kit: itemId,
    }));
  };
  const handleDeviceKit = (isChecked, itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      device_extra_items: isChecked
        ? [...prevData.device_extra_items, itemId]
        : prevData.device_extra_items.filter((id) => id !== itemId),
    }));
  };
  const handleCategoryKit = (isChecked, itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      category_extra_items: isChecked
        ? [...prevData.category_extra_items, itemId]
        : prevData.category_extra_items.filter((id) => id !== itemId),
    }));
  };
  const handleFinishType = (itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      finish_id: itemId,
    }));
  };
  const handleAddCart = (e) => {
    // console.log(addToCartData);
    if (user.apiToken) {
      // Convert to JSON string
      const requestBody = JSON.stringify(addToCartData);
      Api.ProdDetailAddToCart(requestBody)
        .then((res) => {
          // console.log(res.data);
          showSuccessAlert("Skin Added In Your Cart SuccessFully");
          navigate("/cart");
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      e.stopPropagation();
      setLoginAsideActive(true);
    }
  };
  const renderSwitch = (param) => {
    switch (param) {
      // case 1 to show stepOne form and passing nextStep,
      // prevStep, and handleInputData as handleFormData method as prop and also formData as value to the form
      case 1:
        return (
          <>
            <div id="body">
              <div className="out-wrap ">
                <div className="tab-content">
                  <div className="tab-pane active">
                    {/* Show the currently active sub-theme set */}
                    <div key={subThemeWithSkins[activeSubTheme]?.sub_theme_id}>
                      <h5>
                        {subThemeWithSkins[activeSubTheme]?.sub_theme_name}
                      </h5>
                      <div className="row">
                        {subThemeWithSkins[activeSubTheme]?.skins_arr.map(
                          (each_skin) => (
                            <EachThumbnail
                              key={each_skin.id}
                              {...each_skin}
                              activeSkin={activeSkin}
                              handlePrevClick={handlePrevClick}
                              nextStep={nextStep}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Previous and Next buttons */}
                  <div className="prev-btn" onClick={handlePrevSubTheme}>
                    &#8249;
                  </div>
                  <div className="next-btn" onClick={handleNextSubTheme}>
                    &#8250;
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <Customize
            prevStep={prevStep}
            nextStep={nextStep}
            skinRelatedKit={skinRelatedKit}
            onCheckboxChange={handleSkinKit}
            addToCartData={addToCartData}
          />
        );
      case 3:
        return (
          <>
            <div className="col-md-12">
              <div className="row ">
                <div className="col-md-12">
                  <h6 className="font-color">Related Products</h6>
                </div>
                <RelatedProducts
                  prevStep={prevStep}
                  nextStep={nextStep}
                  deviceRelatedKit={deviceRelatedKit}
                  onCheckboxChange={handleDeviceKit}
                  addToCartData={addToCartData}
                />
                <CategoryRelatedProducts
                  prevStep={prevStep}
                  nextStep={nextStep}
                  categoryRelatedKit={categoryRelatedKit}
                  onCheckboxChange={handleCategoryKit}
                  addToCartData={addToCartData}
                />
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <AddToCart
            prevStep={prevStep}
            nextStep={nextStep}
            finishes={finishes}
            onCheckboxChange={handleFinishType}
            handleAddCart={handleAddCart}
          />
        );
      default:
        return <AddToCart prevStep={prevStep} nextStep={nextStep} />;
    }
  };

  return (
    <>
      <div className="container p-2">
        <div id="body" className="row">
          <div className="col-md-4">
            <button className="btn back_btn" onClick={() => navigate(-1)}>
              <span
                style={{
                  padding: "11px 13px ",
                  margin: "0px 10px 0px -20px",
                }}
              >
                <i className="fa fa-backward" aria-hidden="true"></i>
              </span>
              Back
            </button>
          </div>
          <div className="col-md-6 col-6 ">
            {currentStep === 1 && (
              <div className="part_head details-wrap out-wrap">
                <ul id="tabs" className="main_thmes nav-tabs" role="tablist">
                  {deviceRelatedThemes.map((theme, index) => (
                    <li
                      key={theme.id}
                      onClick={() => handleTabClick(index)}
                      ref={(element) => (tabRefs.current[index] = element)}
                    >
                      <a
                        className={activeTab === index ? "active" : ""}
                        href={`#tab${index + 1}`}
                        aria-controls="home"
                        role="tab"
                        data-toggle="tab"
                      >
                        {theme.theme_name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="col-md-2 col-6 text-right">
            <button
              className="prev_btn btn"
              onClick={prevStep}
              disabled={currentStep === 1}
            >
              <i className="fa fa-arrow-left"></i>
            </button>
            <button
              className="next_btn btn"
              onClick={nextStep}
              disabled={currentStep === 4}
            >
              <span className="circle-animation"></span>
              <i className="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-4">
            <div className="pro_det_img">
              <img src={activeSkin?.skin_img} alt={activeSkin?.skin_name} />
            </div>
            <div className="reltd_pro">
              <div className="row">
                {colorRelatedSkins.map((eachColor) => (
                  <EachThumbnail
                    key={eachColor.id}
                    {...eachColor}
                    handlePrevClick={handlePrevClick}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row skin_box scroll">
              {renderSwitch(currentStep)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
