import React, { useRef, useState } from "react";
import EachThemeBox from "./EachThemeBox";

const EachPartAccordion = ({ id, mainThemes }) => {
  const [activeTab, setActiveTab] = useState(mainThemes[0]?.id || null);
  const tabsRef = useRef(null);

  const handleTabClick = (corresponding) => {
    setActiveTab(corresponding);

    if (tabsRef.current) {
      const tabsContainer = tabsRef.current;
      const tabs = Array.from(tabsContainer.children);
      const activeTabIndex = tabs.findIndex(
        (tab) => tab.getAttribute('data-id') === corresponding
      );

      if (activeTabIndex !== -1) {
        const activeTab = tabs[activeTabIndex];
        const scrollOptions = {
          behavior: 'smooth',
          block: 'nearest',
          inline: 'left',
        };

        activeTab.scrollIntoView(scrollOptions);
      }
    }
  };
  return (
    <div className='card-body pb-8'>
      <div id='body'>
        <ul id='tabs' className='main_thmes nav-tabs ' ref={tabsRef}>
          {mainThemes &&
            mainThemes
              .filter((each_main) => each_main.sub_themes.length > 0)
              .map((each_main) => (
                <li key={each_main.id}>
                  <a
                    style={{
                      color: 'rgb(152 152 152)',
                      fontSize: '1.01rem',
                      textAlign: 'center',
                      margin: '2px 10px',
                    }}
                    data-id={each_main.id}
                    className={activeTab === each_main.id ? 'active' : ''}
                    onClick={() => handleTabClick(each_main.id)}
                  >
                    {each_main.theme_name}
                  </a>
                </li>
              ))}
        </ul>
        <div className='tabContent'>
          {mainThemes &&
            mainThemes.map((each_main) =>
              activeTab === each_main.id ? (
                <EachThemeBox
                  key={each_main.id}
                  part_id={id}
                  mask_img={id}
                  subThemes={each_main.sub_themes}
                />
              ) : null
            )}
        </div>
      </div>
    </div>
  );
};

export default EachPartAccordion;
