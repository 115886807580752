import React, { useEffect, useState } from "react";
import Api from "../../helpers/Api";
import EachSkinItem from "./EachSkinItem";
import Swal from "sweetalert2";
import { showConfirmAlert, showSuccessAlert } from "../../helpers/SweetAlert";
import { useNavigate } from "react-router-dom";
import { ToastSuccess } from "../../helpers/Toast";
import EachDeviceKitItem from "./EachDeviceKitItem";
import EachCommonKitItem from "./EachCommonKitItem";

const Cart = () => {
  const [skins, setSkins] = useState([]);
  const [commonKitsInCart, setCommonKitsInCart] = useState([]);
  const [deviceKitsInCart, setDeviceKitsInCart] = useState([]);
  const [updateBtn, setUpdateBtn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Api.CartPage()
      .then((res) => {
        const { skins_in_cart, device_kits_in_cart, common_kits_in_cart } =
          res.data;
        setSkins(skins_in_cart);
        setCommonKitsInCart(common_kits_in_cart);
        setDeviceKitsInCart(device_kits_in_cart);
        // console.log(skins_in_cart);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleUpdateQuantity = (itemId, newQuantity) => {
    // Find the item in the cart and update its quantity
    const updatedSkins = skins.map((skin) => {
      if (skin.id === itemId) {
        return { ...skin, quantity: newQuantity };
      }
      return skin;
    });
    setSkins(updatedSkins);
    setUpdateBtn(true); // Enable the update button after quantity changes
  };

  const handleRemoveItem = (itemId) => {
    showConfirmAlert(
      "Are You Sure To Remove This Item From Cart",
      "Yes",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        // Remove the item from the cart
        const updatedSkins = skins.filter((skin) => skin.id !== itemId);
        setSkins(updatedSkins);
        setUpdateBtn(true); // Enable the update button after removing an item
      }
      // if (result.isConfirmed) {
      //   Swal.fire("Removed!", "", "success");
      // } else if (result.isDenied) {
      //   Swal.fire("Changes are not saved", "", "info");
      // }
    });
  };
  const updateDeviceKitQty = (itemId, newQuantity) => {
    // Find the item in the cart and update its quantity
    const updatedDeviceKit = deviceKitsInCart.map((d) => {
      if (d.id === itemId) {
        return { ...d, quantity: newQuantity };
      }
      return d;
    });
    setDeviceKitsInCart(updatedDeviceKit);
    setUpdateBtn(true); // Enable the update button after quantity changes
  };
  const handleDeviceKitRemoveItem = (itemId) => {
    showConfirmAlert(
      "Are You Sure To Remove This Item From Cart",
      "Yes",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        // Remove the item from the cart
        const updatedDeviceKit = deviceKitsInCart.filter(
          (d) => d.id !== itemId
        );
        setDeviceKitsInCart(updatedDeviceKit);
        setUpdateBtn(true); // Enable the update button after removing an item
      }
    });
  };
  const updateCommonKitQty = (itemId, newQuantity) => {
    // Find the item in the cart and update its quantity
    const updatedCommonKit = commonKitsInCart.map((d) => {
      if (d.id === itemId) {
        return { ...d, quantity: newQuantity };
      }
      return d;
    });
    setCommonKitsInCart(updatedCommonKit);
    setUpdateBtn(true); // Enable the update button after quantity changes
  };
  const handleCommonKitRemoveItem = (itemId) => {
    showConfirmAlert(
      "Are You Sure To Remove This Item From Cart",
      "Yes",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        // Remove the item from the cart
        const updatedCommonKit = commonKitsInCart.filter(
          (d) => d.id !== itemId
        );
        setCommonKitsInCart(updatedCommonKit);
        setUpdateBtn(true); // Enable the update button after removing an item
      }
    });
  };
  const handleUpdate = () => {
    // showConfirmAlert("Do you want to save the changes?", "Save", "cancel").then(
    //   (result) => {
    //     if (result.isConfirmed) {

    //     } else if (result.isDenied) {
    //       Swal.fire("Changes are not saved", "", "info");
    //     }
    //   }
    // );
    let updatedData = {
      skins: skins,
      commonKitsInCart: commonKitsInCart,
      deviceKitsInCart: deviceKitsInCart,
      updateBtn: updateBtn,
    };
    // Convert to JSON string
    const requestBody = JSON.stringify(updatedData);
    Api.UpdateCart(requestBody)
      .then((res) => {
        // console.log(res.data);
        // ToastSuccess("Cart Updated SuccessFully");
        navigate("/checkout");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="container pt-4">
      <div className="mb-10 cart-table ">
        {skins.map((skin) => (
          <EachSkinItem
            {...skin}
            key={skin.id}
            updateQuantity={handleUpdateQuantity}
            removeItem={handleRemoveItem}
          />
        ))}
        {deviceKitsInCart.map((device_kit) => (
          <EachDeviceKitItem
            key={device_kit.id}
            {...device_kit}
            updateDeviceKitQty={updateDeviceKitQty}
            removeItem={handleDeviceKitRemoveItem}
          />
        ))}
        {commonKitsInCart.map((common_kit) => (
          <EachCommonKitItem
            key={common_kit.id}
            {...common_kit}
            updateCommonKitQty={updateCommonKitQty}
            removeItem={handleCommonKitRemoveItem}
          />
        ))}
      </div>
      <div className="mb-10 cart-table">
        <div className="row ">
          <div className="col-md-8"></div>
          <div className="col-md-4">
            <div className="btn cart_checkout d-flex">
              <button className="btn back_btn cart_btn3" onClick={handleUpdate}>
                Proceed to checkout
                <span
                  style={{ padding: "9px 12px ", margin: "0px -51px 0px 9px" }}
                >
                  <i className="fa fa-forward" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
