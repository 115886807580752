import React from 'react';

const EachThumbnail = ({
  thumbnail_img,
  skin_name,
  reg_price,
  handlePrevClick,
  activeSkin,
  id,
}) => {
  const isActiveSkin = activeSkin.id === id;
  return (
    <>
      <div className='col-md-2'>
        <span className='font-color'>{skin_name}</span>
        <div
          style={{ width: '100px' }}
          className='text-center'
          onClick={() => handlePrevClick(id)}
        >
          <div className='thumbnail_img'>
          <img src={thumbnail_img} alt={skin_name} className={`img-fluid ${isActiveSkin?'active_skin':''}`} />
          </div>
          {/* <h6 className='text-capitalize'>{skin_name}</h6> */}
          <h6>₹ {reg_price}</h6>
        </div>
      </div>
    </>
  );
};

export default EachThumbnail;
