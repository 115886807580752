import React from 'react';
import { Link } from 'react-router-dom';
const ShareButton = () => {
  return (
    <button className='btn share_btn' id='social'>
      <i className='fa fa-share-alt' aria-hidden='true'></i>Share
      <ul className='list hidden'>
        <li>
          <Link to={'#'}>
            <i className='font-size-16 fab fa-facebook-f'></i>
          </Link>
        </li>
        <li>
          <Link to={'#'}>
            <i className='font-size-16 fab fa-whatsapp'></i>
          </Link>
        </li>
        <li>
          <Link to={'#'}>
            <i className='font-size-16 fab fa-instagram'></i>
          </Link>
        </li>
        <li>
          <Link to={'#'}>
            <i className='font-size-16 fab fa-twitter'></i>
          </Link>
        </li>
        {/* <li>
          <Link to={'#'}>
            <i className='font-size-16 ec ec-favorites'></i>
          </Link>
        </li> */}
      </ul>
    </button>
  );
};

export default ShareButton;
