import React from "react";
const Customize = ({ skinRelatedKit, onCheckboxChange,addToCartData  }) => {
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-12">
          <h6 className="font-color"> Customize</h6>
        </div>
        {skinRelatedKit.map((each_kit_item, i) => (
          <div className="row col-md-12 mt-5 mb-2" key={each_kit_item.id}>
            <div className="col-md-4">
              <div className="skin_box_img">
                <img
                  src={each_kit_item.img_path}
                  alt={each_kit_item.heading}
                  className="img-fluid"
                  style={{ width: "100px" }}
                />
              </div>
            </div>
            <div className="col-md-7">
              <h4 className="font-color">{each_kit_item.heading}</h4>
              <p className="font-17">{each_kit_item.desc}</p>
              <p className="font-17">{each_kit_item.price}</p>
            </div>
            <div className="col-md-1">
              <input
                type="radio"
                name="skin_related_kit"
                className="check-box"
                checked={each_kit_item.id === addToCartData.skin_related_kit}
                onChange={(e) => onCheckboxChange(each_kit_item.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Customize;
