import React from "react";

const AddToCart = ({ finishes, onCheckboxChange, handleAddCart }) => {
  return (
    <div className="col-md-12">
      <div className="row ">
        <div className="col-md-12">
          <h6 className="font-color">Add To Cart</h6>
        </div>
      </div>
      <h4 className="font-color">Choose Finish Type</h4>

      {/* <div className='col-md-4'>
            <div className='skin_box_img'>
              <img
                src=''
                alt=''
                className='img-fluid'
                style={{ width: '100px' }}
              />
            </div>
          </div> */}
      {/* there finish.id is skin finish relation id */}
      {finishes.map((finish) => (
        <div className="row mt-5 mb-2" key={finish.id}>
          <div className="col-md-7">
            <p className="font-17">{finish.finish_name}</p>
          </div>
          <div className="col-md-3">
            <p className="font-17">₹{finish.reg_price}</p>
          </div>
          <div className="col-md-1">
            <input
              type="radio"
              name="finish_id"
              className="check-box"
              value={finish.id}
              onChange={(e) => onCheckboxChange(finish.id)}
            />
          </div>
        </div>
      ))}

      <div className="col-md-12 mt-5 text-right">
        <button
          type="button"
          className="btn  theme_flt_btn"
          onClick={handleAddCart}
        >
          Add To Cart
        </button>
      </div>
    </div>
  );
};

export default AddToCart;
