import React from "react";

const EachSkinCartItem = ({
  skin_img,
  model_no,
  reg_price,
  quantity,
}) => {
  return (
    <li className="border-bottom pb-3 mb-3">
      <div>
        <ul className="list-unstyled row mx-n2">
          <li className="px-2 col-auto cart_img">
            <img className="img-fluid" src={skin_img} alt="" />
          </li>
          <li className="px-2 col">
            <h5 className="text-gray-5 font-size-14 font-weight-bold">
              Ultra Wireless S50 Headphones S50 with Bluetooth
            </h5>
            <span className="text-gray-5 font-size-14">
              {quantity} × {reg_price}
            </span>
          </li>
          {/* <li className="px-2 col-auto">
            <a href="#" className="text-gray-90">
              <i className="ec ec-close-remove" />
            </a>
          </li> */}
        </ul>
      </div>
    </li>
  );
};

export default EachSkinCartItem;
