import React from "react";

const EachCommonKitItem = ({
  heading,
  desc,
  img_path,
  price,
  quantity,
  updateCommonKitQty,
  removeItem,
  id,
}) => {
  const handleIncrement = () => {
    updateCommonKitQty(id, quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      updateCommonKitQty(id, quantity - 1);
    }
  };

  const handleRemove = () => {
    removeItem(id);
  };
  return (
    <div className="row cart-item-row">
      <div className="col-md-1">
        <span className="text-gray-32 font-size-26" onClick={handleRemove}>
          ×
        </span>
      </div>
      <div className="col-md-2 ">
        <div className="image_box">
          <img className="img-fluid  p-1 " src={img_path} alt={heading} />
        </div>
      </div>
      <div className="col-md-2">
        <div>
          <button className="cart_col_btn btn text-secondary">Product</button>
          <p className="cart_col_value">{heading}</p>
        </div>
      </div>
      <div className="col-md-2">
        <div>
          <button className="cart_col_btn btn text-secondary">Desc</button>
          <p className="cart_col_value">{desc}</p>
        </div>
      </div>

      <div className="col-md-1">
        <button className="cart_col_btn btn text-secondary">Price</button>
        <p className="cart_col_value">{price}</p>
      </div>
      <div className="col-md-2">
        <button className="cart_col_btn btn text-secondary">Quantity</button>
        {/* Quantity */}

        <div className="js-quantity row align-items-center">
          <div className="d-flex cart_col_value ml-5">
            <button
              className="qty_btn btn text-secondary"
              onClick={handleDecrement}
            >
              <small className="fas fa-minus btn-icon__inner" />
            </button>

            <div className="qty_count ml-2">
              <input
                className="qty_input js-result form-control h-auto border-0 rounded p-0 shadow-none"
                type="text"
                value={quantity}
                readOnly
              />
            </div>
            <button
              className=" ml-2 btn text-secondary qty_btn "
              onClick={handleIncrement}
            >
              <small className="fas fa-plus btn-icon__inner_1" />
            </button>
          </div>
        </div>

        {/* End Quantity */}
      </div>
      <div className="col-md-2">
        <button className="cart_col_btn btn text-secondary">Total</button>
        <p className="cart_col_value">{quantity * price}</p>
      </div>
    </div>
  );
};

export default EachCommonKitItem;
