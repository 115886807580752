import React, { useEffect, useState } from "react";
import CouponAccordion from "./CouponAccordion";
import Api from "../../helpers/Api";
import SkinProduct from "./SkinProduct";
import CommonKitProduct from "./CommonKitProduct";
import DeviceKitProduct from "./DeviceKitProduct";
import BillingDetail from "./BillingDetail";
import ShippingDetail from "./ShippingDetail";

const Checkout = () => {
  const [skins, setSkins] = useState([]);
  const [commonKitsInCart, setCommonKitsInCart] = useState([]);
  const [deviceKitsInCart, setDeviceKitsInCart] = useState([]);

  useEffect(() => {
    Api.CheckoutPage()
      .then((res) => {
        const { skins_in_cart, device_kits_in_cart, common_kits_in_cart } =
          res.data;
        setSkins(skins_in_cart);
        setCommonKitsInCart(common_kits_in_cart);
        setDeviceKitsInCart(device_kits_in_cart);
        // console.log(skins_in_cart);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // Function to calculate the subtotal based on the "price" field of each object
  const calculateSubtotal = (items) => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0);
  };
  return (
    <div className="container">
      <CouponAccordion />
      <div className="row">
        <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
          <div className="pl-lg-3 ">
            <div className="bg-gray-1 rounded-lg">
              {/* Order Summary */}
              <div className="p-4 mb-4 checkout-table">
                {/* Title */}
                <div className="border-bottom border-color-1 mb-5">
                  <h3 className="section-title mb-0 pb-2 font-size-25">
                    Your order
                  </h3>
                </div>
                {/* End Title */}
                {/* Product Content */}
                <table className="table">
                  <thead>
                    <tr>
                      <th className="product-name">Product</th>
                      <th className="product-total">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skins.map((skin_in_cart) => (
                      <SkinProduct key={skin_in_cart.id} {...skin_in_cart} />
                    ))}

                    {commonKitsInCart.map((common_kit_in_cart) => (
                      <CommonKitProduct
                        key={common_kit_in_cart.id}
                        {...common_kit_in_cart}
                      />
                    ))}

                    {deviceKitsInCart.map((device_kit_in_cart) => (
                      <DeviceKitProduct
                        key={device_kit_in_cart.id}
                        {...device_kit_in_cart}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Subtotal</th>
                      <td>
                        $
                        {calculateSubtotal([
                          ...skins,
                          ...commonKitsInCart,
                          ...deviceKitsInCart,
                        ]).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>Flat rate $300.00</td>
                    </tr>
                    <tr>
                      <th>Total</th>
                      <td>
                        <strong>
                          {calculateSubtotal([
                            ...skins,
                            ...commonKitsInCart,
                            ...deviceKitsInCart,
                          ]).toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                {/* End Product Content */}
                <div className="border-top border-width-3 border-color-1 pt-3 mb-3">
                  {/* Basics Accordion */}
                  <div id="basicsAccordion1">
                    {/* Card */}
                    <div className="border-bottom border-color-1 border-dotted-bottom">
                      <div className="p-3" id="basicsHeadingOne">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="stylishRadio1"
                            name="stylishRadio"
                            defaultChecked=""
                          />
                          <label
                            className="custom-control-label form-label"
                            htmlFor="stylishRadio1"
                            data-toggle="collapse"
                            data-target="#basicsCollapseOnee"
                            aria-expanded="true"
                            aria-controls="basicsCollapseOnee"
                          >
                            Direct bank transfer
                          </label>
                        </div>
                      </div>
                      <div
                        id="basicsCollapseOnee"
                        className="collapse show border-top border-color-1 border-dotted-top bg-dark-lighter"
                        aria-labelledby="basicsHeadingOne"
                        data-parent="#basicsAccordion1"
                      >
                        <div className="p-4">
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                          Your order will not be shipped until the funds have
                          cleared in our account.
                        </div>
                      </div>
                    </div>
                    {/* End Card */}
                    {/* Card */}
                    <div className="border-bottom border-color-1 border-dotted-bottom">
                      <div className="p-3" id="basicsHeadingTwo">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="secondStylishRadio1"
                            name="stylishRadio"
                          />
                          <label
                            className="custom-control-label form-label"
                            htmlFor="secondStylishRadio1"
                            data-toggle="collapse"
                            data-target="#basicsCollapseTwo"
                            aria-expanded="false"
                            aria-controls="basicsCollapseTwo"
                          >
                            Check payments
                          </label>
                        </div>
                      </div>
                      <div
                        id="basicsCollapseTwo"
                        className="collapse border-top border-color-1 border-dotted-top bg-dark-lighter"
                        aria-labelledby="basicsHeadingTwo"
                        data-parent="#basicsAccordion1"
                      >
                        <div className="p-4">
                          Please send a check to Store Name, Store Street, Store
                          Town, Store State / County, Store Postcode.
                        </div>
                      </div>
                    </div>
                    {/* End Card */}
                    {/* Card */}
                    <div className="border-bottom border-color-1 border-dotted-bottom">
                      <div className="p-3" id="basicsHeadingThree">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="thirdstylishRadio1"
                            name="stylishRadio"
                          />
                          <label
                            className="custom-control-label form-label"
                            htmlFor="thirdstylishRadio1"
                            data-toggle="collapse"
                            data-target="#basicsCollapseThree"
                            aria-expanded="false"
                            aria-controls="basicsCollapseThree"
                          >
                            Cash on delivery
                          </label>
                        </div>
                      </div>
                      <div
                        id="basicsCollapseThree"
                        className="collapse border-top border-color-1 border-dotted-top bg-dark-lighter"
                        aria-labelledby="basicsHeadingThree"
                        data-parent="#basicsAccordion1"
                      >
                        <div className="p-4">Pay with cash upon delivery.</div>
                      </div>
                    </div>
                    {/* End Card */}
                    {/* Card */}
                    <div className="border-bottom border-color-1 border-dotted-bottom">
                      <div className="p-3" id="basicsHeadingFour">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="FourstylishRadio1"
                            name="stylishRadio"
                          />
                          <label
                            className="custom-control-label form-label"
                            htmlFor="FourstylishRadio1"
                            data-toggle="collapse"
                            data-target="#basicsCollapseFour"
                            aria-expanded="false"
                            aria-controls="basicsCollapseFour"
                          >
                            PayPal{" "}
                            <a href="#" className="text-blue">
                              What is PayPal?
                            </a>
                          </label>
                        </div>
                      </div>
                      <div
                        id="basicsCollapseFour"
                        className="collapse border-top border-color-1 border-dotted-top bg-dark-lighter"
                        aria-labelledby="basicsHeadingFour"
                        data-parent="#basicsAccordion1"
                      >
                        <div className="p-4">
                          Pay via PayPal; you can pay with your credit card if
                          you don’t have a PayPal account.
                        </div>
                      </div>
                    </div>
                    {/* End Card */}
                  </div>
                  {/* End Basics Accordion */}
                </div>
                <div className="form-group d-flex align-items-center justify-content-between px-3 mb-5">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="defaultCheck10"
                      required=""
                      data-msg="Please agree terms and conditions."
                      data-error-class="u-has-error"
                      data-success-class="u-has-success"
                    />
                    <label
                      className="form-check-label form-label"
                      htmlFor="defaultCheck10"
                    >
                      I have read and agree to the website{" "}
                      <a href="#" className="text-blue">
                        terms and conditions{" "}
                      </a>
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary-dark-w btn-block btn-pill font-size-20 mb-3 py-3"
                >
                  Place order
                </button>
              </div>
              {/* End Order Summary */}
            </div>
          </div>
        </div>
        <div className="col-lg-7 order-lg-1">
          <div className="pb-7 mb-7">
            {/* Title */}
            <div className="border-bottom border-color-1 mb-5">
              <h3 className="section-title mb-0 pb-2 font-size-25">
                Billing details
              </h3>
            </div>
            {/* End Title */}
            {/* Billing Form */}
            <BillingDetail />
            {/* End Billing Form */}
            {/* Title */}
            <div className="border-bottom border-color-1 mb-5">
              <h3 className="section-title mb-0 pb-2 font-size-25">
                Shipping Details details
              </h3>
            </div>
            {/* End Title */}
            {/* Accordion */}
            <ShippingDetail />
            {/* End Accordion */}
            {/* Input */}
            {/* <div className="js-form-message mb-6">
              <label className="form-label">Order notes (optional)</label>
              <div className="input-group">
                <textarea
                  className="form-control p-5"
                  rows={4}
                  name="text"
                  placeholder="Notes about your order, e.g. special notes for delivery."
                  defaultValue={""}
                />
              </div>
            </div> */}
            {/* End Input */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
