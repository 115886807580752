import CustomAxios from "../utils/CustomAxios";

const axiosForJSON = CustomAxios("application/json");
const Api = {
  // const axiosForMultipart = CustomAxios('multipart/form-data');
  HomePage: () => {
    return axiosForJSON.get("home-page");
  },
  FilterPage: (category_id) => {
    return axiosForJSON.get(`category-filter-page/${category_id}`);
  },
  getDevicesByBrand:(brand_id)=>{

    return axiosForJSON.get(`get-devices-by-brand/${brand_id}`);
  },
  makeFilterPost:(data)=>{
    return axiosForJSON.post(`/make-filter-post`,data);
  },
  DetailPage: (skin_id) => {
    return axiosForJSON.get(`/product-detail/${skin_id}`);
  },
  CustomPage: (device_id) => {
    return axiosForJSON.get(`/product-detail-customize/${device_id}`);
  },
  getRelatedKitBySkin:(skin_id)=>{

    return axiosForJSON.get(`get-skin-related-kit/${skin_id}`);
  },
  ProdDetailFilter:(s_id,t_id)=>{

    return axiosForJSON.get(`/device-and-theme-based-skins/${s_id}/${t_id}`);
  },
  ProdDetailAddToCart:(data)=>{

    return axiosForJSON.post(`/product-detail-add-to-cart`,data);
  },
  Login:(data)=>{

    return axiosForJSON.post(`/auth/login`,data);
  },
  CartPage: () => {
    return axiosForJSON.get(`/cart-page`);
  },
  UpdateCart: (data) => {
    return axiosForJSON.post(`/update-cart`,data);
  },
  CartHeader: () => {
    return axiosForJSON.get(`cart-notifications`);
  },
  CheckoutPage: () => {
    return axiosForJSON.get(`/checkout`);
  },
 
};

export default Api;
