import React from 'react';
import { Link } from 'react-router-dom';
const EachProductCard = ({ skin_img, skin_name, category_name, id,reg_price }) => {
  return (
    <div className='col-md-3 p-card'>
      <div className='content'>
        {' '}
        <Link
          to={`/${category_name}/${id}`}
          className='font-size-12 text-gray-5'
        >
          <div className='front'>
            <img src={skin_img} alt={skin_name} className='img-fluid cat_img' />
          </div>
        </Link>
        <div className='back from-bottom'>
          <div className='row pl-3 mt-2 mb-3 price-card '>
            <h4 className='text-center pt-2 price-c'> ₹ {reg_price}</h4>
            &nbsp;&nbsp;&nbsp;
            <div className='product-btn d-none'>
              <button
                className='btn btn-link text-secondary drop_btn'
                style={{ borderRadius: '50px' }}
              >
                <span
                  style={{
                    boxShadow:
                      '-1.5px -1.5px 4px rgba(255, 255, 255, 0.96), 1px 1px 2px rgba(0, 0, 0, 0.28)',
                  }}
                >
                  <img
                    className='img-icon  p-1 '
                    src='../../assets/img/customize-3.png'
                    alt=' Description'
                  />
                </span>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className='btn btn-link text-secondary drop_btn'
                style={{ borderRadius: '50px' }}
              >
                <span>
                  <img
                    className='img-icon  p-1 '
                    src='../../assets/img/customize-3.png'
                    alt=' Description'
                  />
                </span>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className='btn btn-link text-secondary drop_btn'
                style={{ borderRadius: '50px' }}
              >
                <span>
                  <img
                    className='img-icon  p-1 '
                    src='../../assets/img/customize-3.png'
                    alt=' Description'
                  />
                </span>
              </button>
              &nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EachProductCard;
