import React from "react";

const SkinProduct = ({ reg_price, quantity, skin_name }) => {
  return (
    <tr className="cart_item">
      <td>
        {skin_name}
        <strong className="product-quantity">× {quantity}</strong>
      </td>
      <td>${reg_price * quantity}</td>
    </tr>
  );
};

export default SkinProduct;
